import { Box } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { PageHeader } from '@planning/components/molecules/PageHeader'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { useTranslate } from '@tolgee/react'
import { Breadcrumbs, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useOrderBreadcrumbs } from '../../hooks/useOrderBreadcrumbs'
import { HandleOrderPageLocationState } from '../Order'
import { SelectOrderViewStore } from '../Order/stores/SelectOrderViewStore'
import CheckoutContainerForm from './components/Organism/CheckoutContainerForm'

const DEFAULT_RETURN_PATH = PATH_PLANNING.visits

export const CheckoutContainerPage: FC = observer(() => {
  const { orderItemStore, containerJourneyDataStore, containerItemStore } = usePlanningStore()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigate = useNavigate()

  const { getCheckoutContainerBreadcrumbs } = useOrderBreadcrumbs()

  const { state, pathname } = useLocation() as {
    state: HandleOrderPageLocationState
    pathname: string
  }

  const { orderId: orderIdParam } = useParams()
  const orderId = Number(orderIdParam)

  const selectOrderViewStore = useMemo(
    () => new SelectOrderViewStore(orderItemStore, containerJourneyDataStore),
    [containerJourneyDataStore, orderItemStore],
  )

  const order = computed(() => orderItemStore.elements[orderId]?.data).get()

  const { loading: orderLoading } = useAsyncFetch(async () => {
    await orderItemStore.fetchById(orderId)
  }, [orderId, orderItemStore])

  const { loading: visitLoading } = useAsyncFetch(async () => {
    if (!order) return

    const { inbound, outbound } = await orderItemStore.getOrderContainerVisitDetails(order)
    if (!inbound && !outbound) return

    selectOrderViewStore.selectContainerVisit([inbound, outbound])
    if (order.containerId) selectOrderViewStore.setContainerId(order.containerId)
  }, [order, orderItemStore, selectOrderViewStore])

  const onBack = () => {
    navigate(state?.returnPath || DEFAULT_RETURN_PATH)
  }

  const container = computed(
    () => containerItemStore.elements[selectOrderViewStore.selectedContainerId],
  ).get()

  const getRouteBreadcrumbs = useCallback(
    (pathname: string) => {
      if (!order) return []

      const path = pathname.split('/').filter(Boolean)

      const isVesselVisit = path.includes('vessel-visits')

      if (isVesselVisit) {
        return getCheckoutContainerBreadcrumbs(order)
      } else return []
    },
    [getCheckoutContainerBreadcrumbs, order],
  )

  return (
    <InitializationWrapper
      isInitializing={!selectOrderViewStore.selectedContainerVisit || orderLoading || visitLoading}
    >
      <>
        <Breadcrumbs items={getRouteBreadcrumbs(pathname)} />
        <PageHeader
          formId='checkout-container-form'
          onBack={onBack}
          saveTitle={t('checkoutContainer', 'Check out')}
          pageTitle={t('checkoutContainer', 'Check out container')}
        ></PageHeader>

        <Box
          paddingX={theme.customSpacing.l}
          paddingY={theme.customSpacing.m}
          gap={theme.customSpacing.m}
          display={'flex'}
          flexDirection={'column'}
        >
          {container?.data && order && (
            <CheckoutContainerForm container={container.data} order={order} />
          )}
        </Box>
      </>
    </InitializationWrapper>
  )
})
