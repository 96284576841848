import { createSvgIcon } from '@mui/material'

export const RailMountedGantryIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='10' y='2' width='4' height='7' rx='1' fill='currentColor' />
    <path
      d='M3 2C2.44772 2 2 2.44772 2 3V4C2 4.55228 2.44772 5 3 5H4V15H3C2.44772 15 2 15.4477 2 16V19C2 19.5523 2.44772 20 3 20H4V21C4 21.5523 4.44772 22 5 22C5.55228 22 6 21.5523 6 21V20H7C7.55228 20 8 19.5523 8 19V16C8 15.4477 7.55228 15 7 15H6V5H18V15H17C16.4477 15 16 15.4477 16 16V19C16 19.5523 16.4477 20 17 20H18V21C18 21.5523 18.4477 22 19 22C19.5523 22 20 21.5523 20 21V20H21C21.5523 20 22 19.5523 22 19V16C22 15.4477 21.5523 15 21 15H20V5H21C21.5523 5 22 4.55228 22 4V3C22 2.44772 21.5523 2 21 2H3Z'
      fill='currentColor'
    />
  </svg>,
  'RailMountedGantryIcon',
)
