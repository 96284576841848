import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface IProps {
  formId: string
  onBack?: () => void
  saveTitle?: string
  cancelTitle?: string
}

export function PageHeaderActions({ formId, onBack, saveTitle, cancelTitle }: IProps) {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const defaultSaveTitle = saveTitle ?? t('save', 'Save')
  const defaultCancelTitle = cancelTitle ?? t('cancel', 'Cancel')

  return (
    <Stack gap={theme.customSpacing.m} direction='row' alignItems='center'>
      <Button variant='text' onClick={onBack} color='inherit'>
        {defaultCancelTitle}
      </Button>

      <Button variant='contained' color='primary' form={formId} type='submit'>
        {defaultSaveTitle}
      </Button>
    </Stack>
  )
}
