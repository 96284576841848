import { CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { PATH_PLANNING } from '@planning/page-url-paths'

export const getPathOfCarrierType = (carrierVisitType: CarrierType) =>
  carrierVisitType === CarrierType.Vessel ? PATH_PLANNING.vesselVisits : PATH_PLANNING.railVisits

export const getDirectionName = (direction: CarrierVisitDirection) =>
  direction === CarrierVisitDirection.Inbound ? 'discharge' : 'load'

export const getVisitOrderPagePath = (
  carrierVisitType: CarrierType,
  carrierVisitId: number,
  orderId: number,
) => `${getPathOfCarrierType(carrierVisitType)}/${carrierVisitId}/order/${orderId}`

export const getCheckoutContainerPagePath = (
  carrierVisitType: CarrierType,
  carrierVisitId: number,
  orderId: number,
) => `${getPathOfCarrierType(carrierVisitType)}/${carrierVisitId}/checkout/${orderId}`

export const getVisitOrderPageReturnPath = (
  carrierVisitType: CarrierType,
  direction: CarrierVisitDirection,
  carrierVisitId: number,
) => `${getPathOfCarrierType(carrierVisitType)}/${carrierVisitId}/${getDirectionName(direction)}`
