import { createSvgIcon } from '@mui/material'

export const RubberTyredGantryIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.22222 2C3.60857 2 3.11111 2.49746 3.11111 3.11111V4.22222C3.11111 4.83587 3.60857 5.33333 4.22222 5.33333H4.77778V14.2222H3.11111C2.49746 14.2222 2 14.7197 2 15.3333V19.7778C2 20.3914 2.49746 20.8889 3.11111 20.8889V16.4444C3.11111 15.8308 3.60857 15.3333 4.22222 15.3333H7.55556C8.16921 15.3333 8.66667 15.8308 8.66667 16.4444V20.8889C9.28032 20.8889 9.77778 20.3914 9.77778 19.7778V15.3333C9.77778 14.7197 9.28032 14.2222 8.66667 14.2222H7V5.33333H17V14.2222H15.3333C14.7197 14.2222 14.2222 14.7197 14.2222 15.3333V19.7778C14.2222 20.3914 14.7197 20.8889 15.3333 20.8889V16.4444C15.3333 15.8308 15.8308 15.3333 16.4444 15.3333H19.7778C20.3914 15.3333 20.8889 15.8308 20.8889 16.4444V20.8889C21.5025 20.8889 22 20.3914 22 19.7778V15.3333C22 14.7197 21.5025 14.2222 20.8889 14.2222H19.2222V5.33333H19.7778C20.3914 5.33333 20.8889 4.83587 20.8889 4.22222V3.11111C20.8889 2.49746 20.3914 2 19.7778 2H4.22222Z'
      fill='currentColor'
    />
    <path
      d='M4.22222 17.5556C4.22222 16.9419 4.71968 16.4444 5.33333 16.4444H6.44444C7.05809 16.4444 7.55556 16.9419 7.55556 17.5556V20.8889C7.55556 21.5025 7.05809 22 6.44444 22H5.33333C4.71968 22 4.22222 21.5025 4.22222 20.8889V17.5556Z'
      fill='currentColor'
    />
    <path
      d='M17.5556 16.4444C16.9419 16.4444 16.4444 16.9419 16.4444 17.5556V20.8889C16.4444 21.5025 16.9419 22 17.5556 22H18.6667C19.2803 22 19.7778 21.5025 19.7778 20.8889V17.5556C19.7778 16.9419 19.2803 16.4444 18.6667 16.4444H17.5556Z'
      fill='currentColor'
    />
  </svg>,
  'RubberTyredGantryIcon',
)
