import { Stack, Typography } from '@mui/material'
import { useCarrierTypeOptions } from '@storage/app/carriers/hooks'
import {
  useCargoStatusOptions,
  useContainerSizeOptions,
  useDangerousOptions,
  useReeferOptions,
} from '@storage/app/container-turnovers/hooks'
import { useContainerHeightOptions } from '@storage/app/container-turnovers/hooks/use-container-height-options.hook'
import { useContainerTypeOptions } from '@storage/app/container-turnovers/hooks/use-container-type-options.hook'
import { useOOGOptions } from '@storage/app/container-turnovers/hooks/use-oog-options.hook'
import { UnknownNumberValue } from '@storage/app/models'
import { ControlledSelect, ControlledTextField } from '@storage/components/form-controls'
import { ControlledMultipleChipSelect } from '@storage/components/form-controls/ControlledMultipleChipSelect'
import { useTranslate } from '@tolgee/react'
import { Control, FormState } from 'react-hook-form'
import { AllocationRuleTemplateFormProfile } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'

interface AllocationRuleCommonFieldsProps {
  control: Control<AllocationRuleTemplateFormProfile>
  formState: FormState<AllocationRuleTemplateFormProfile>
  weightClassesOptions: any[]
  customers: any[]
  openWithOnlyContainerNumber?: boolean
  showCarrierTypeField?: boolean
}

export const AllocationRuleCommonFields = ({
  control,
  formState,
  weightClassesOptions,
  customers,
  openWithOnlyContainerNumber,
  showCarrierTypeField = false,
}: AllocationRuleCommonFieldsProps) => {
  const { t } = useTranslate()

  const containerSizeOptions = useContainerSizeOptions()
  const containerTypeOptions = useContainerTypeOptions()
  const containerHeightOptions = useContainerHeightOptions()
  const cargoStatusOptions = useCargoStatusOptions()
  const reeferOptions = useReeferOptions()
  const dangerousOptions = useDangerousOptions()
  const oogOptions = useOOGOptions()
  const carrierOptions = useCarrierTypeOptions(true)

  if (openWithOnlyContainerNumber) {
    return (
      <ControlledTextField
        name='facets.containerNumber'
        formState={formState}
        control={control}
        fullWidth
        label={t('containerNumber', 'Container number')}
      />
    )
  }

  return (
    <>
      <Typography variant='subtitle1'>{t('facets', 'Facets')}</Typography>
      <Stack direction='row' gap={1}>
        <ControlledSelect
          formState={formState}
          control={control}
          fullWidth
          options={containerSizeOptions}
          label={t('size', 'Size')}
          name='facets.size'
        />
        <ControlledTextField
          name='facets.containerOperator'
          formState={formState}
          control={control}
          fullWidth
          label={t('containerOperator', 'Container Operator')}
        />
      </Stack>
      <Stack direction='row' gap={1}>
        <ControlledSelect
          allOption
          formState={formState}
          control={control}
          fullWidth
          options={containerTypeOptions}
          label={t('type', 'Type')}
          name='facets.containerType'
        />
        <ControlledSelect
          allOption
          formState={formState}
          control={control}
          fullWidth
          options={containerHeightOptions}
          label={t('height', 'Height')}
          name='facets.containerHeight'
        />
      </Stack>
      <Stack direction='row' gap={1}>
        <ControlledTextField
          name='facets.consignee'
          formState={formState}
          control={control}
          fullWidth
          label={t('consignee', 'Consignee')}
        />
        <ControlledTextField
          name='facets.portOfDischarge'
          formState={formState}
          control={control}
          fullWidth
          label={t('portOfDischarge', 'Port of discharge')}
        />
      </Stack>
      <ControlledSelect
        formState={formState}
        control={control}
        options={[
          ...customers.map(x => ({ label: x.name, value: x.id.toString() })),
          { label: t('unknown', 'Unknown'), value: UnknownNumberValue.toString() },
        ]}
        label={t('customer', 'Customer')}
        name='facets.customer'
      />
      {weightClassesOptions.length > 0 && (
        <>
          <Typography variant='captionBold'>{t('weightClasses', 'Weight Classes')}</Typography>
          <Stack direction='row'>
            <ControlledMultipleChipSelect
              formState={formState}
              control={control}
              options={weightClassesOptions}
              name='facets.weightClasses'
              hasTooltip
            />
          </Stack>
        </>
      )}

      <ControlledSelect
        formState={formState}
        control={control}
        options={cargoStatusOptions}
        label={t('cargoStatus', 'Cargo Status')}
        name='facets.isEmpty'
      />

      <ControlledSelect
        formState={formState}
        control={control}
        options={reeferOptions}
        label={t('reefer', 'Reefer')}
        name='facets.isReefer'
      />

      <ControlledSelect
        formState={formState}
        control={control}
        options={dangerousOptions}
        label={t('dangerous', 'Dangerous')}
        name='facets.isDangerous'
      />

      <ControlledSelect
        formState={formState}
        control={control}
        options={oogOptions}
        label={t('oog', 'OOG')}
        name='facets.isOOG'
      />

      {showCarrierTypeField && (
        <ControlledSelect
          formState={formState}
          control={control}
          options={carrierOptions}
          label={t('carrierType', 'Carrier Type')}
          name='facets.carrierType'
        />
      )}
    </>
  )
}
