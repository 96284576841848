import { Grid, Paper, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ContainerResponseDto, CustomsResponseDto, OrderResponseDto } from '@planning/app/api'
import { FeatureFlag } from '@planning/components/FeatureFlags'
import {
  ChangeTransportSubFormType,
  RegisteredChangeTransportSubForm,
} from '@planning/components/organisms/ChangeTransportSubForm'
import { CustomsSubForm } from '@planning/components/organisms/CustomsSubForm'
import { CargoDetailsSummary } from '@planning/pages/Order/components/CargoDetailsSummary'
import { ContainerSummary } from '@planning/pages/Order/components/ContainerSummary'
import { useTranslate } from '@tolgee/react'
import { FormField, useFormStore } from '@tom-ui/utils'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react-lite'

interface IProps {
  container?: ContainerResponseDto
  order?: OrderResponseDto
}

const CheckoutContainerForm = observer(({ container, order }: IProps) => {
  const { t } = useTranslate()

  const formStore = useFormStore<{
    customs: CustomsResponseDto[]
    transport: ChangeTransportSubFormType
    departureTime: Dayjs
  }>()

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {container && <ContainerSummary container={container} />}
        </Grid>

        <Grid item xs={12} sm={6}>
          {order && (
            <CargoDetailsSummary
              isEmpty={order.isEmpty}
              dangerousGoods={order.isDangerous}
              seals={order.hasSeals}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Paper variant='outlined' sx={{ mt: '1rem', p: '1rem' }}>
            <Typography variant='subtitle1' sx={{ mb: '.5rem' }}>
              {t('containerDepartureDetails', 'Container departure details')}
            </Typography>

            <Grid item sm={2}>
              <FormField formStore={formStore} name={'departureTime'} initialValue={dayjs()}>
                {store => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label={t('departureTime', 'Departure time')}
                      value={store.value}
                      onChange={newValue => console.log(newValue)}
                      slotProps={{
                        textField: {
                          required: true,
                          variant: 'outlined',
                          // helperText: errors.eta ? t('fieldIsRequired', 'Field is required.') : '',
                          // error: !!errors.eta,
                          sx: { width: '100%', marginTop: '10px' },
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              </FormField>
            </Grid>

            <Paper variant='outlined' sx={{ mt: '1rem', p: '1rem' }}>
              <Typography variant='subtitle2' sx={{ mb: '1rem' }}>
                {t('transportType', 'Transport Type')}
              </Typography>

              <RegisteredChangeTransportSubForm name={'transport'} formStore={formStore} />
            </Paper>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <FeatureFlag name='customs-v-1'>
            <Paper variant='outlined' sx={{ mt: '1rem', p: '1rem' }}>
              <CustomsSubForm
                name={'customs'}
                formStore={formStore}
                initialValue={order?.customs}
                allowDelete={true}
                showHeader={true}
              />
            </Paper>
          </FeatureFlag>
        </Grid>
      </Grid>
    </form>
  )
})

export default CheckoutContainerForm
