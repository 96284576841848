import { useTranslate } from '@tolgee/react'
import { useOperationsStore } from '@tom-ui/operations'
import { useStorageStore, YardOperationControl } from '@tom-ui/storage'
import { ConfirmationDialog, Loader } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ContainerYardOperationViewStore } from './ContainerYardOperationViewStore'

interface Props {
  store: ContainerYardOperationViewStore
}
export const ContainerYardOperationDialog = observer(({ store }: Props) => {
  const { t } = useTranslate()

  const { yardOperationControlStore } = useStorageStore()
  const { appStore } = useOperationsStore()

  const handleConfirm = async () => {
    try {
      store.setLoading(true)
      if (store.containerNumber && store.orderId)
        await yardOperationControlStore.onConfirm(store.containerNumber, store.orderId)
      appStore.setShowAlert(
        'success',
        t('positionWasUpdatedSuccessfully', 'Position was updated successfully'),
      )
      store.setHasSelectedDestination(false)
      store.close()
    } catch (e) {
      appStore.setShowAlert('error', t('failedToUpdatePosition', 'Failed to update position'))
    } finally {
      store.setLoading(false)
    }
  }

  if (!store.containerNumber || !store.orderId) {
    return
  }

  return (
    <>
      <Loader show={store.isLoading} />
      <ConfirmationDialog
        open={store.isOpen}
        title={t('correctYardLocation', 'Correct yard')}
        primaryActionText={t('confirm', 'Confirm')}
        primaryActionDisabled={!store.hasSelectedDestination}
        onConfirm={handleConfirm}
        closeLabel={t('cancel', 'Cancel')}
        onClose={() => store.close()}
        maxWidth='lg'
        hasBorderTop
      >
        <YardOperationControl
          containerNumber={store.containerNumber}
          handleChange={(value: any[]) => {
            store.setHasSelectedDestination(!!value)
          }}
          orderId={store.orderId}
        />
      </ConfirmationDialog>
    </>
  )
})
