/* tslint:disable */
/* eslint-disable */
/**
 * TOM OPERATIONS SERVICE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AssignEquipmentCommand
 */
export interface AssignEquipmentCommand {
  /**
   *
   * @type {number}
   * @memberof AssignEquipmentCommand
   */
  equipmentId: number
  /**
   *
   * @type {number}
   * @memberof AssignEquipmentCommand
   */
  workingAreaId: number
  /**
   *
   * @type {WorkAreaEquipmentBindingTypes}
   * @memberof AssignEquipmentCommand
   */
  assignmentType: WorkAreaEquipmentBindingTypes
}
/**
 *
 * @export
 * @interface AssignRequest
 */
export interface AssignRequest {
  /**
   *
   * @type {number}
   * @memberof AssignRequest
   */
  equipmentId: number
  /**
   *
   * @type {number}
   * @memberof AssignRequest
   */
  workingAreaId: number
  /**
   *
   * @type {WorkAreaEquipmentBindingTypes}
   * @memberof AssignRequest
   */
  assignmentType: WorkAreaEquipmentBindingTypes
}
/**
 *
 * @export
 * @interface AssignToJobByTallymanRequest
 */
export interface AssignToJobByTallymanRequest {
  /**
   *
   * @type {number}
   * @memberof AssignToJobByTallymanRequest
   */
  equipmentId: number
  /**
   *
   * @type {number}
   * @memberof AssignToJobByTallymanRequest
   */
  carrierVisitId: number
  /**
   *
   * @type {number}
   * @memberof AssignToJobByTallymanRequest
   */
  orderId: number
}
/**
 *
 * @export
 * @interface AutoGenerateWorkQueuesCommand
 */
export interface AutoGenerateWorkQueuesCommand {
  /**
   *
   * @type {number}
   * @memberof AutoGenerateWorkQueuesCommand
   */
  carrierVisitId: number
  /**
   *
   * @type {Array<number>}
   * @memberof AutoGenerateWorkQueuesCommand
   */
  craneIds: Array<number>
}
/**
 *
 * @export
 * @interface AutoGenerateWorkQueuesRequest
 */
export interface AutoGenerateWorkQueuesRequest {
  /**
   *
   * @type {number}
   * @memberof AutoGenerateWorkQueuesRequest
   */
  carrierVisitId: number
  /**
   *
   * @type {Array<number>}
   * @memberof AutoGenerateWorkQueuesRequest
   */
  craneIds: Array<number>
}
/**
 *
 * @export
 * @enum {string}
 */

export const BerthSide = {
  Unspecified: 'Unspecified',
  Port: 'Port',
  Starboard: 'Starboard',
} as const

export type BerthSide = (typeof BerthSide)[keyof typeof BerthSide]

/**
 *
 * @export
 * @interface CancelStartJobCommand
 */
export interface CancelStartJobCommand {
  /**
   *
   * @type {number}
   * @memberof CancelStartJobCommand
   */
  workInstructionId: number
  /**
   *
   * @type {number}
   * @memberof CancelStartJobCommand
   */
  equipmentId: number
}
/**
 *
 * @export
 * @interface CancelStartJobRequest
 */
export interface CancelStartJobRequest {
  /**
   *
   * @type {number}
   * @memberof CancelStartJobRequest
   */
  workInstructionId: number
  /**
   *
   * @type {number}
   * @memberof CancelStartJobRequest
   */
  equipmentId: number
}
/**
 *
 * @export
 * @interface CargoUnitDto
 */
export interface CargoUnitDto {
  /**
   *
   * @type {number}
   * @memberof CargoUnitDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof CargoUnitDto
   */
  containerNumber: string
  /**
   *
   * @type {string}
   * @memberof CargoUnitDto
   */
  containerIsoCode?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CargoUnitDto
   */
  isDamaged: boolean
  /**
   *
   * @type {boolean}
   * @memberof CargoUnitDto
   */
  isReefer: boolean
  /**
   *
   * @type {boolean}
   * @memberof CargoUnitDto
   */
  isDangerous: boolean
  /**
   *
   * @type {number}
   * @memberof CargoUnitDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CargoUnitDto
   */
  isPluggedIn: boolean
  /**
   *
   * @type {boolean}
   * @memberof CargoUnitDto
   */
  isEmpty: boolean
  /**
   *
   * @type {CargoUnitDtoCurrentLocation}
   * @memberof CargoUnitDto
   */
  currentLocation: CargoUnitDtoCurrentLocation
  /**
   *
   * @type {string}
   * @memberof CargoUnitDto
   */
  portOfDischarge?: string | null
  /**
   *
   * @type {ContainerHeight}
   * @memberof CargoUnitDto
   */
  height?: ContainerHeight | null
  /**
   *
   * @type {number}
   * @memberof CargoUnitDto
   */
  length?: number | null
  /**
   *
   * @type {ContainerType}
   * @memberof CargoUnitDto
   */
  type: ContainerType
}
/**
 *
 * @export
 * @interface CargoUnitDtoCurrentLocation
 */
export interface CargoUnitDtoCurrentLocation {
  /**
   *
   * @type {ContainerPositionType}
   * @memberof CargoUnitDtoCurrentLocation
   */
  type: ContainerPositionType
  /**
   *
   * @type {string}
   * @memberof CargoUnitDtoCurrentLocation
   */
  yardBlockName?: string | null
  /**
   *
   * @type {CarrierType}
   * @memberof CargoUnitDtoCurrentLocation
   */
  yardBlockType?: CarrierType | null
  /**
   *
   * @type {Array<string>}
   * @memberof CargoUnitDtoCurrentLocation
   */
  yardBays?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof CargoUnitDtoCurrentLocation
   */
  locationName?: string | null
  /**
   *
   * @type {string}
   * @memberof CargoUnitDtoCurrentLocation
   */
  vesselLocationName?: string | null
  /**
   *
   * @type {number}
   * @memberof CargoUnitDtoCurrentLocation
   */
  equipmentId?: number | null
  /**
   *
   * @type {string}
   * @memberof CargoUnitDtoCurrentLocation
   */
  equipmentName?: string | null
  /**
   *
   * @type {EquipmentType}
   * @memberof CargoUnitDtoCurrentLocation
   */
  equipmentType?: EquipmentType | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const CarrierType = {
  Universal: 'Universal',
  Truck: 'Truck',
  Vessel: 'Vessel',
  Train: 'Train',
} as const

export type CarrierType = (typeof CarrierType)[keyof typeof CarrierType]

/**
 *
 * @export
 * @interface CarrierVisitDto
 */
export interface CarrierVisitDto {
  /**
   *
   * @type {number}
   * @memberof CarrierVisitDto
   */
  id: number
  /**
   *
   * @type {CarrierType}
   * @memberof CarrierVisitDto
   */
  type: CarrierType
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof CarrierVisitDto
   */
  status: CarrierVisitStatus
  /**
   *
   * @type {BerthSide}
   * @memberof CarrierVisitDto
   */
  berthSide: BerthSide
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  carrierName?: string | null
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  etd?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CarrierVisitDto
   */
  berthNames: Array<string>
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  craneSplitStartTime?: string | null
  /**
   *
   * @type {BerthSide}
   * @memberof CarrierVisitDto
   */
  craneSplitBerthSide?: BerthSide | null
  /**
   *
   * @type {boolean}
   * @memberof CarrierVisitDto
   */
  hasGeometry?: boolean | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const CarrierVisitStatus = {
  Expected: 'Expected',
  Arrived: 'Arrived',
  InOperation: 'InOperation',
  Departed: 'Departed',
  Completed: 'Completed',
} as const

export type CarrierVisitStatus = (typeof CarrierVisitStatus)[keyof typeof CarrierVisitStatus]

/**
 *
 * @export
 * @enum {string}
 */

export const ContainerHeight = {
  Standard: 'Standard',
  High: 'High',
  Low: 'Low',
} as const

export type ContainerHeight = (typeof ContainerHeight)[keyof typeof ContainerHeight]

/**
 *
 * @export
 * @enum {string}
 */

export const ContainerPositionType = {
  Vessel: 'Vessel',
  Truck: 'Truck',
  Yard: 'Yard',
  Equipment: 'Equipment',
  Handover: 'Handover',
  Unknown: 'Unknown',
  Berth: 'Berth',
  Repair: 'Repair',
  Warehouse: 'Warehouse',
  Customs: 'Customs',
  Weights: 'Weights',
  RequireYardReservation: 'RequireYardReservation',
  Train: 'Train',
  YardReservationFailed: 'YardReservationFailed',
  TrailerParkingLot: 'TrailerParkingLot',
} as const

export type ContainerPositionType =
  (typeof ContainerPositionType)[keyof typeof ContainerPositionType]

/**
 *
 * @export
 * @enum {string}
 */

export const ContainerService = {
  Stuffing: 'Stuffing',
  Stripping: 'Stripping',
} as const

export type ContainerService = (typeof ContainerService)[keyof typeof ContainerService]

/**
 *
 * @export
 * @interface ContainerSlotDto
 */
export interface ContainerSlotDto {
  /**
   *
   * @type {WorkInstructionForContainerSlotDto}
   * @memberof ContainerSlotDto
   */
  workInstruction?: WorkInstructionForContainerSlotDto | null
  /**
   *
   * @type {NonNumericInfoDto}
   * @memberof ContainerSlotDto
   */
  nonNumeric?: NonNumericInfoDto | null
  /**
   *
   * @type {string}
   * @memberof ContainerSlotDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof ContainerSlotDto
   */
  containerNumber: string
  /**
   *
   * @type {string}
   * @memberof ContainerSlotDto
   */
  isoCode: string
  /**
   *
   * @type {ContainerSlotPositionType}
   * @memberof ContainerSlotDto
   */
  containerPositionType: ContainerSlotPositionType
  /**
   *
   * @type {string}
   * @memberof ContainerSlotDto
   */
  portOfLoading?: string | null
  /**
   *
   * @type {string}
   * @memberof ContainerSlotDto
   */
  portOfDischarge?: string | null
  /**
   *
   * @type {string}
   * @memberof ContainerSlotDto
   */
  grossWeight?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ContainerSlotDto
   */
  isEmpty?: boolean | null
  /**
   *
   * @type {boolean}
   * @memberof ContainerSlotDto
   */
  isReefer?: boolean | null
  /**
   *
   * @type {boolean}
   * @memberof ContainerSlotDto
   */
  isDangerous?: boolean | null
  /**
   *
   * @type {number}
   * @memberof ContainerSlotDto
   */
  portOfDischargeIndex: number
  /**
   *
   * @type {boolean}
   * @memberof ContainerSlotDto
   */
  isHighCube?: boolean | null
  /**
   *
   * @type {boolean}
   * @memberof ContainerSlotDto
   */
  isFlatRack?: boolean | null
  /**
   *
   * @type {boolean}
   * @memberof ContainerSlotDto
   */
  isDryContainer?: boolean | null
  /**
   *
   * @type {boolean}
   * @memberof ContainerSlotDto
   */
  isOOG?: boolean | null
  /**
   *
   * @type {boolean}
   * @memberof ContainerSlotDto
   */
  isRestow?: boolean | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContainerSlotPositionType = {
  Twenty: 'TWENTY',
  Forthy: 'FORTHY',
  FortyFront: 'FORTY_FRONT',
  FortyBack: 'FORTY_BACK',
} as const

export type ContainerSlotPositionType =
  (typeof ContainerSlotPositionType)[keyof typeof ContainerSlotPositionType]

/**
 *
 * @export
 * @interface ContainerStowageLocationDto
 */
export interface ContainerStowageLocationDto {
  /**
   *
   * @type {number}
   * @memberof ContainerStowageLocationDto
   */
  id: number
  /**
   *
   * @type {ContainerVesselDirectionType}
   * @memberof ContainerStowageLocationDto
   */
  operationType: ContainerVesselDirectionType
  /**
   *
   * @type {VesselArea}
   * @memberof ContainerStowageLocationDto
   */
  vesselArea: VesselArea
  /**
   *
   * @type {string}
   * @memberof ContainerStowageLocationDto
   */
  containerNumber: string
  /**
   *
   * @type {number}
   * @memberof ContainerStowageLocationDto
   */
  bay: number
  /**
   *
   * @type {number}
   * @memberof ContainerStowageLocationDto
   */
  row: number
  /**
   *
   * @type {number}
   * @memberof ContainerStowageLocationDto
   */
  tier: number
  /**
   *
   * @type {boolean}
   * @memberof ContainerStowageLocationDto
   */
  hasTwin: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContainerType = {
  Unknown: 'Unknown',
  General: 'General',
  GeneralVentilated: 'GeneralVentilated',
  Reefer: 'Reefer',
  OpenTop: 'OpenTop',
  Platform: 'Platform',
  Tank: 'Tank',
  Bulk: 'Bulk',
  Foldable: 'Foldable',
  AirSurface: 'AirSurface',
  Livestock: 'Livestock',
  Auto: 'Auto',
  Fish: 'Fish',
  NonCargoCarrying: 'NonCargoCarrying',
} as const

export type ContainerType = (typeof ContainerType)[keyof typeof ContainerType]

/**
 *
 * @export
 * @enum {string}
 */

export const ContainerVesselDirectionType = {
  Inbound: 'Inbound',
  Outbound: 'Outbound',
  RemainOnBoard: 'RemainOnBoard',
  Restow: 'Restow',
  NonNumeric: 'NonNumeric',
} as const

export type ContainerVesselDirectionType =
  (typeof ContainerVesselDirectionType)[keyof typeof ContainerVesselDirectionType]

/**
 *
 * @export
 * @interface CoolingOrderContainerDto
 */
export interface CoolingOrderContainerDto {
  /**
   *
   * @type {number}
   * @memberof CoolingOrderContainerDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof CoolingOrderContainerDto
   */
  containerNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderContainerDto
   */
  length?: number | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderContainerDto
   */
  yardBlock?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CoolingOrderContainerDto
   */
  yardBays?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderContainerDto
   */
  locationName?: string | null
}
/**
 *
 * @export
 * @interface CoolingOrderDeviationDto
 */
export interface CoolingOrderDeviationDto {
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDeviationDto
   */
  ideal?: number | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDeviationDto
   */
  min?: number | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDeviationDto
   */
  max?: number | null
}
/**
 *
 * @export
 * @interface CoolingOrderDto
 */
export interface CoolingOrderDto {
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDto
   */
  cargoUnitId: number
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDto
   */
  containerNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDto
   */
  containerLength?: number | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDto
   */
  customerId?: number | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDto
   */
  customer?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CoolingOrderDto
   */
  isTemperatureRequired: boolean
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDto
   */
  outboundWorkInstructionId?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CoolingOrderDto
   */
  isOutboundWorkInstructionFinished: boolean
  /**
   *
   * @type {boolean}
   * @memberof CoolingOrderDto
   */
  isOutboundReadyForOperations: boolean
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDto
   */
  requestedTemperature: number
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDto
   */
  lastRecordedTemperature?: number | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDto
   */
  lastRecordedTemperatureDateTime?: string | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDto
   */
  lastRecordedAdditionalInfo?: string | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDto
   */
  lastRecordedHumidity?: number | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDto
   */
  lastRecordedVentilation?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CoolingOrderDto
   */
  isPluggedIn: boolean
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDto
   */
  pluggedOutTime?: string | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDto
   */
  nextCheckDateTime?: string | null
  /**
   *
   * @type {Array<ReeferTemperatureDto>}
   * @memberof CoolingOrderDto
   */
  reeferTemperatures?: Array<ReeferTemperatureDto> | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDto
   */
  deleted?: string | null
  /**
   *
   * @type {OrderStatus}
   * @memberof CoolingOrderDto
   */
  status: OrderStatus
  /**
   *
   * @type {boolean}
   * @memberof CoolingOrderDto
   */
  isPlugInRequired: boolean
  /**
   *
   * @type {boolean}
   * @memberof CoolingOrderDto
   */
  isPlugOutRequired: boolean
  /**
   *
   * @type {boolean}
   * @memberof CoolingOrderDto
   */
  isTemperatureCheckRequired: boolean
  /**
   *
   * @type {CoolingOrderDeviationDto}
   * @memberof CoolingOrderDto
   */
  temperatureDeviation?: CoolingOrderDeviationDto | null
  /**
   *
   * @type {CoolingOrderDeviationDto}
   * @memberof CoolingOrderDto
   */
  humidityDeviation?: CoolingOrderDeviationDto | null
  /**
   *
   * @type {CoolingOrderDeviationDto}
   * @memberof CoolingOrderDto
   */
  ventilationDeviation?: CoolingOrderDeviationDto | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDto
   */
  yardBlock?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CoolingOrderDto
   */
  yardBays?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDto
   */
  locationName?: string | null
  /**
   *
   * @type {CarrierType}
   * @memberof CoolingOrderDto
   */
  inboundCarrierType?: CarrierType | null
  /**
   *
   * @type {CarrierType}
   * @memberof CoolingOrderDto
   */
  outboundCarrierType?: CarrierType | null
  /**
   *
   * @type {WorkInstructionStatus}
   * @memberof CoolingOrderDto
   */
  inboundWorkInstructionStatus?: WorkInstructionStatus | null
}
/**
 *
 * @export
 * @interface CoolingOrderDtoV2
 */
export interface CoolingOrderDtoV2 {
  /**
   *
   * @type {OrderStatus}
   * @memberof CoolingOrderDtoV2
   */
  status: OrderStatus
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2
   */
  requestedTemperature: number
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2
   */
  minRequestedTemperature?: number | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2
   */
  maxRequestedTemperature?: number | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2
   */
  idealHumidity?: number | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2
   */
  minIdealHumidity?: number | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2
   */
  maxIdealHumidity?: number | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2
   */
  idealVentilation?: number | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2
   */
  minIdealVentilation?: number | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2
   */
  maxIdealVentilation?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CoolingOrderDtoV2
   */
  isMonitoringRequired: boolean
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2
   */
  monitoringFrequency?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CoolingOrderDtoV2
   */
  isPlugInRequired: boolean
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2
   */
  customerId?: number | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDtoV2
   */
  customer?: string | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDtoV2
   */
  deleted?: string | null
  /**
   *
   * @type {CoolingOrderDtoV2Container}
   * @memberof CoolingOrderDtoV2
   */
  container: CoolingOrderDtoV2Container
  /**
   *
   * @type {CoolingOrderDtoV2InboundWorkInstruction}
   * @memberof CoolingOrderDtoV2
   */
  inboundWorkInstruction: CoolingOrderDtoV2InboundWorkInstruction
  /**
   *
   * @type {CoolingOrderDtoV2InboundWorkInstruction}
   * @memberof CoolingOrderDtoV2
   */
  outboundWorkInstruction: CoolingOrderDtoV2InboundWorkInstruction
  /**
   *
   * @type {Array<ReeferTemperatureDto>}
   * @memberof CoolingOrderDtoV2
   */
  reeferTemperatures?: Array<ReeferTemperatureDto> | null
}
/**
 *
 * @export
 * @interface CoolingOrderDtoV2Container
 */
export interface CoolingOrderDtoV2Container {
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2Container
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDtoV2Container
   */
  containerNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2Container
   */
  length?: number | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDtoV2Container
   */
  yardBlock?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CoolingOrderDtoV2Container
   */
  yardBays?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof CoolingOrderDtoV2Container
   */
  locationName?: string | null
}
/**
 *
 * @export
 * @interface CoolingOrderDtoV2InboundWorkInstruction
 */
export interface CoolingOrderDtoV2InboundWorkInstruction {
  /**
   *
   * @type {number}
   * @memberof CoolingOrderDtoV2InboundWorkInstruction
   */
  id?: number | null
  /**
   *
   * @type {WorkInstructionStatus}
   * @memberof CoolingOrderDtoV2InboundWorkInstruction
   */
  status?: WorkInstructionStatus | null
  /**
   *
   * @type {CarrierType}
   * @memberof CoolingOrderDtoV2InboundWorkInstruction
   */
  carrierType?: CarrierType | null
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof CoolingOrderDtoV2InboundWorkInstruction
   */
  carrierVisitStatus?: CarrierVisitStatus | null
}
/**
 *
 * @export
 * @interface CoolingOrderWorkInstructionDto
 */
export interface CoolingOrderWorkInstructionDto {
  /**
   *
   * @type {number}
   * @memberof CoolingOrderWorkInstructionDto
   */
  id?: number | null
  /**
   *
   * @type {WorkInstructionStatus}
   * @memberof CoolingOrderWorkInstructionDto
   */
  status?: WorkInstructionStatus | null
  /**
   *
   * @type {CarrierType}
   * @memberof CoolingOrderWorkInstructionDto
   */
  carrierType?: CarrierType | null
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof CoolingOrderWorkInstructionDto
   */
  carrierVisitStatus?: CarrierVisitStatus | null
}
/**
 *
 * @export
 * @interface CraneQueueDto
 */
export interface CraneQueueDto {
  /**
   *
   * @type {number}
   * @memberof CraneQueueDto
   */
  bay: number
  /**
   *
   * @type {OperationType}
   * @memberof CraneQueueDto
   */
  operationType: OperationType
  /**
   *
   * @type {VesselArea}
   * @memberof CraneQueueDto
   */
  vesselArea: VesselArea
  /**
   *
   * @type {string}
   * @memberof CraneQueueDto
   */
  craneName: string
  /**
   *
   * @type {string}
   * @memberof CraneQueueDto
   */
  vesselName: string
  /**
   *
   * @type {number}
   * @memberof CraneQueueDto
   */
  visitId: number
  /**
   *
   * @type {number}
   * @memberof CraneQueueDto
   */
  order: number
  /**
   *
   * @type {WorkQueueInfoDto}
   * @memberof CraneQueueDto
   */
  nextQueue?: WorkQueueInfoDto | null
  /**
   *
   * @type {number}
   * @memberof CraneQueueDto
   */
  secondsToTheEnd: number
  /**
   *
   * @type {Array<WorkQueueItem>}
   * @memberof CraneQueueDto
   */
  items: Array<WorkQueueItem>
}
/**
 *
 * @export
 * @interface CreateEquipmentCommand
 */
export interface CreateEquipmentCommand {
  /**
   *
   * @type {string}
   * @memberof CreateEquipmentCommand
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof CreateEquipmentCommand
   */
  maxWeight?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateEquipmentCommand
   */
  maxHeight?: number | null
  /**
   *
   * @type {EquipmentType}
   * @memberof CreateEquipmentCommand
   */
  equipmentType: EquipmentType
  /**
   *
   * @type {Array<YardBlockAssignmentDto>}
   * @memberof CreateEquipmentCommand
   */
  yardBlockAssignments: Array<YardBlockAssignmentDto>
  /**
   *
   * @type {boolean}
   * @memberof CreateEquipmentCommand
   */
  isOnMaintenance: boolean
}
/**
 *
 * @export
 * @interface CreateInternalWorkInstructionCommand
 */
export interface CreateInternalWorkInstructionCommand {
  /**
   *
   * @type {Array<string>}
   * @memberof CreateInternalWorkInstructionCommand
   */
  containerNumbers: Array<string>
  /**
   *
   * @type {Array<YardBlockSlotLocation>}
   * @memberof CreateInternalWorkInstructionCommand
   */
  destination: Array<YardBlockSlotLocation>
}
/**
 *
 * @export
 * @interface CreateInternalWorkInstructionRequest
 */
export interface CreateInternalWorkInstructionRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof CreateInternalWorkInstructionRequest
   */
  containerNumbers: Array<string>
  /**
   *
   * @type {Array<YardBlockSlotLocation>}
   * @memberof CreateInternalWorkInstructionRequest
   */
  destination: Array<YardBlockSlotLocation>
}
/**
 *
 * @export
 * @interface CreateReeferTemperatureCommand
 */
export interface CreateReeferTemperatureCommand {
  /**
   *
   * @type {number}
   * @memberof CreateReeferTemperatureCommand
   */
  cargoUnitId: number
  /**
   *
   * @type {number}
   * @memberof CreateReeferTemperatureCommand
   */
  temperature?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateReeferTemperatureCommand
   */
  humidity?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateReeferTemperatureCommand
   */
  ventilation?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CreateReeferTemperatureCommand
   */
  isPluggedIn: boolean
  /**
   *
   * @type {string}
   * @memberof CreateReeferTemperatureCommand
   */
  recordedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateReeferTemperatureCommand
   */
  additionalInfo?: string | null
}
/**
 *
 * @export
 * @interface CreateRequest
 */
export interface CreateRequest {
  /**
   *
   * @type {string}
   * @memberof CreateRequest
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof CreateRequest
   */
  maxWeight?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateRequest
   */
  maxHeight?: number | null
  /**
   *
   * @type {EquipmentType}
   * @memberof CreateRequest
   */
  equipmentType: EquipmentType
  /**
   *
   * @type {Array<YardBlockAssignmentDto>}
   * @memberof CreateRequest
   */
  yardBlockAssignments: Array<YardBlockAssignmentDto>
  /**
   *
   * @type {boolean}
   * @memberof CreateRequest
   */
  isOnMaintenance: boolean
}
/**
 *
 * @export
 * @interface CreateRequest1
 */
export interface CreateRequest1 {
  /**
   *
   * @type {number}
   * @memberof CreateRequest1
   */
  cargoUnitId: number
  /**
   *
   * @type {number}
   * @memberof CreateRequest1
   */
  temperature?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateRequest1
   */
  humidity?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateRequest1
   */
  ventilation?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CreateRequest1
   */
  isPluggedIn: boolean
  /**
   *
   * @type {string}
   * @memberof CreateRequest1
   */
  recordedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateRequest1
   */
  additionalInfo?: string | null
}
/**
 *
 * @export
 * @interface CreateRequest2
 */
export interface CreateRequest2 {
  /**
   *
   * @type {number}
   * @memberof CreateRequest2
   */
  cargoUnitId: number
  /**
   *
   * @type {ContainerPositionType}
   * @memberof CreateRequest2
   */
  destinationLocationType: ContainerPositionType
  /**
   *
   * @type {string}
   * @memberof CreateRequest2
   */
  yardBlockId?: string | null
  /**
   *
   * @type {number}
   * @memberof CreateRequest2
   */
  equipmentId?: number | null
  /**
   *
   * @type {string}
   * @memberof CreateRequest2
   */
  deadline?: string | null
}
/**
 *
 * @export
 * @interface CreateServiceWorkInstructionCommand
 */
export interface CreateServiceWorkInstructionCommand {
  /**
   *
   * @type {number}
   * @memberof CreateServiceWorkInstructionCommand
   */
  cargoUnitId: number
  /**
   *
   * @type {ContainerPositionType}
   * @memberof CreateServiceWorkInstructionCommand
   */
  destinationLocationType: ContainerPositionType
  /**
   *
   * @type {string}
   * @memberof CreateServiceWorkInstructionCommand
   */
  yardBlockId?: string | null
  /**
   *
   * @type {number}
   * @memberof CreateServiceWorkInstructionCommand
   */
  equipmentId?: number | null
  /**
   *
   * @type {string}
   * @memberof CreateServiceWorkInstructionCommand
   */
  deadline?: string | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const DoorDirection = {
  Anyway: 'Anyway',
  Inward: 'Inward',
  Outward: 'Outward',
} as const

export type DoorDirection = (typeof DoorDirection)[keyof typeof DoorDirection]

/**
 *
 * @export
 * @interface EquipmentDetailStatsDto
 */
export interface EquipmentDetailStatsDto {
  /**
   *
   * @type {string}
   * @memberof EquipmentDetailStatsDto
   */
  name: string
  /**
   *
   * @type {Array<string>}
   * @memberof EquipmentDetailStatsDto
   */
  yardBlockNames: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof EquipmentDetailStatsDto
   */
  carrierVisitNames: Array<string>
}
/**
 *
 * @export
 * @interface EquipmentDto
 */
export interface EquipmentDto {
  /**
   *
   * @type {number}
   * @memberof EquipmentDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof EquipmentDto
   */
  name: string
  /**
   *
   * @type {EquipmentType}
   * @memberof EquipmentDto
   */
  equipmentType: EquipmentType
  /**
   *
   * @type {boolean}
   * @memberof EquipmentDto
   */
  isOnMaintenance: boolean
  /**
   *
   * @type {number}
   * @memberof EquipmentDto
   */
  maxWeight?: number | null
  /**
   *
   * @type {number}
   * @memberof EquipmentDto
   */
  maxHeight?: number | null
  /**
   *
   * @type {Array<EquipmentYardBlockDto>}
   * @memberof EquipmentDto
   */
  yardBlocks?: Array<EquipmentYardBlockDto> | null
  /**
   *
   * @type {Array<EquipmentWorkAreaDto>}
   * @memberof EquipmentDto
   */
  workAreaEquipments?: Array<EquipmentWorkAreaDto> | null
}
/**
 *
 * @export
 * @interface EquipmentPlannedDto
 */
export interface EquipmentPlannedDto {
  /**
   *
   * @type {number}
   * @memberof EquipmentPlannedDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof EquipmentPlannedDto
   */
  name: string
  /**
   *
   * @type {EquipmentType}
   * @memberof EquipmentPlannedDto
   */
  equipmentType: EquipmentType
  /**
   *
   * @type {Array<EquipmentWorkAreaDto>}
   * @memberof EquipmentPlannedDto
   */
  workAreaEquipments?: Array<EquipmentWorkAreaDto> | null
}
/**
 *
 * @export
 * @interface EquipmentStatsDto
 */
export interface EquipmentStatsDto {
  /**
   *
   * @type {EquipmentType}
   * @memberof EquipmentStatsDto
   */
  equipmentType: EquipmentType
  /**
   *
   * @type {Array<EquipmentDetailStatsDto>}
   * @memberof EquipmentStatsDto
   */
  notAssigned: Array<EquipmentDetailStatsDto>
  /**
   *
   * @type {Array<EquipmentDetailStatsDto>}
   * @memberof EquipmentStatsDto
   */
  assignedToYard: Array<EquipmentDetailStatsDto>
  /**
   *
   * @type {Array<EquipmentDetailStatsDto>}
   * @memberof EquipmentStatsDto
   */
  assignedToVisit: Array<EquipmentDetailStatsDto>
  /**
   *
   * @type {Array<EquipmentDetailStatsDto>}
   * @memberof EquipmentStatsDto
   */
  onMaintenance: Array<EquipmentDetailStatsDto>
  /**
   *
   * @type {Array<EquipmentStatsVisitIdDto>}
   * @memberof EquipmentStatsDto
   */
  visits: Array<EquipmentStatsVisitIdDto>
}
/**
 *
 * @export
 * @interface EquipmentStatsVisitIdDto
 */
export interface EquipmentStatsVisitIdDto {
  /**
   *
   * @type {CarrierType}
   * @memberof EquipmentStatsVisitIdDto
   */
  carrierType: CarrierType
  /**
   *
   * @type {string}
   * @memberof EquipmentStatsVisitIdDto
   */
  carrierName?: string | null
  /**
   *
   * @type {number}
   * @memberof EquipmentStatsVisitIdDto
   */
  visitId: number
}
/**
 *
 * @export
 * @enum {string}
 */

export const EquipmentType = {
  Sts: 'STS',
  Rtg: 'RTG',
  Rs: 'RS',
  Ech: 'ECH',
  Tt: 'TT',
  Sc: 'SC',
  Fl: 'FL',
  Rmg: 'RMG',
} as const

export type EquipmentType = (typeof EquipmentType)[keyof typeof EquipmentType]

/**
 *
 * @export
 * @enum {string}
 */

export const EquipmentTypes = {
  None: 'None',
  Sts: 'STS',
  Rtg: 'RTG',
  Rs: 'RS',
  Ech: 'ECH',
  Tt: 'TT',
  Sc: 'SC',
  Fl: 'FL',
  Rmg: 'RMG',
} as const

export type EquipmentTypes = (typeof EquipmentTypes)[keyof typeof EquipmentTypes]

/**
 *
 * @export
 * @interface EquipmentWorkAreaDto
 */
export interface EquipmentWorkAreaDto {
  /**
   *
   * @type {number}
   * @memberof EquipmentWorkAreaDto
   */
  workAreaId: number
  /**
   *
   * @type {string}
   * @memberof EquipmentWorkAreaDto
   */
  yardBlockId?: string | null
  /**
   *
   * @type {boolean}
   * @memberof EquipmentWorkAreaDto
   */
  isInOperation: boolean
  /**
   *
   * @type {string}
   * @memberof EquipmentWorkAreaDto
   */
  crane?: string | null
  /**
   *
   * @type {WorkAreaType}
   * @memberof EquipmentWorkAreaDto
   */
  workAreaType: WorkAreaType
  /**
   *
   * @type {WorkAreaEquipmentBindingTypes}
   * @memberof EquipmentWorkAreaDto
   */
  assignmentType: WorkAreaEquipmentBindingTypes
  /**
   *
   * @type {Array<string>}
   * @memberof EquipmentWorkAreaDto
   */
  assignedBays: Array<string>
}
/**
 *
 * @export
 * @interface EquipmentYardBlockDto
 */
export interface EquipmentYardBlockDto {
  /**
   *
   * @type {string}
   * @memberof EquipmentYardBlockDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof EquipmentYardBlockDto
   */
  name: string
  /**
   *
   * @type {EquipmentType}
   * @memberof EquipmentYardBlockDto
   */
  equipmentType: EquipmentType
  /**
   *
   * @type {Array<string>}
   * @memberof EquipmentYardBlockDto
   */
  bays: Array<string>
}
/**
 *
 * @export
 * @interface FinishJobCommand
 */
export interface FinishJobCommand {
  /**
   *
   * @type {number}
   * @memberof FinishJobCommand
   */
  workInstructionId: number
  /**
   *
   * @type {number}
   * @memberof FinishJobCommand
   */
  equipmentId: number
  /**
   *
   * @type {number}
   * @memberof FinishJobCommand
   */
  nextJobEquipmentId?: number | null
  /**
   *
   * @type {number}
   * @memberof FinishJobCommand
   */
  newCargoUnitId?: number | null
  /**
   *
   * @type {boolean}
   * @memberof FinishJobCommand
   */
  isPluggedIn: boolean
  /**
   *
   * @type {number}
   * @memberof FinishJobCommand
   */
  grossWeight?: number | null
  /**
   *
   * @type {number}
   * @memberof FinishJobCommand
   */
  railcarTrackPositionId?: number | null
  /**
   *
   * @type {number}
   * @memberof FinishJobCommand
   */
  picks?: number | null
  /**
   *
   * @type {ContainerPositionType}
   * @memberof FinishJobCommand
   */
  destinationLocationType?: ContainerPositionType | null
}
/**
 *
 * @export
 * @interface FinishJobRequest
 */
export interface FinishJobRequest {
  /**
   *
   * @type {number}
   * @memberof FinishJobRequest
   */
  workInstructionId: number
  /**
   *
   * @type {number}
   * @memberof FinishJobRequest
   */
  equipmentId: number
  /**
   *
   * @type {number}
   * @memberof FinishJobRequest
   */
  nextJobEquipmentId?: number | null
  /**
   *
   * @type {number}
   * @memberof FinishJobRequest
   */
  newCargoUnitId?: number | null
  /**
   *
   * @type {boolean}
   * @memberof FinishJobRequest
   */
  isPluggedIn: boolean
  /**
   *
   * @type {number}
   * @memberof FinishJobRequest
   */
  grossWeight?: number | null
  /**
   *
   * @type {number}
   * @memberof FinishJobRequest
   */
  railcarTrackPositionId?: number | null
  /**
   *
   * @type {number}
   * @memberof FinishJobRequest
   */
  picks?: number | null
  /**
   *
   * @type {ContainerPositionType}
   * @memberof FinishJobRequest
   */
  destinationLocationType?: ContainerPositionType | null
}
/**
 *
 * @export
 * @interface FinishWorkInstructionJobCommand
 */
export interface FinishWorkInstructionJobCommand {
  /**
   *
   * @type {number}
   * @memberof FinishWorkInstructionJobCommand
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof FinishWorkInstructionJobCommand
   */
  unitAmount?: number | null
}
/**
 *
 * @export
 * @interface FinishWorkInstructionJobRequest
 */
export interface FinishWorkInstructionJobRequest {
  /**
   *
   * @type {number}
   * @memberof FinishWorkInstructionJobRequest
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof FinishWorkInstructionJobRequest
   */
  unitAmount?: number | null
}
/**
 *
 * @export
 * @interface GroupingDto
 */
export interface GroupingDto {
  /**
   *
   * @type {number}
   * @memberof GroupingDto
   */
  groupId: number
  /**
   *
   * @type {string}
   * @memberof GroupingDto
   */
  groupName: string
  /**
   *
   * @type {number}
   * @memberof GroupingDto
   */
  sequence?: number | null
  /**
   *
   * @type {number}
   * @memberof GroupingDto
   */
  groupTotal?: number | null
}
/**
 *
 * @export
 * @interface IssueDto
 */
export interface IssueDto {
  /**
   *
   * @type {number}
   * @memberof IssueDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof IssueDto
   */
  orderId?: number | null
  /**
   *
   * @type {IssueType}
   * @memberof IssueDto
   */
  issueType: IssueType
  /**
   *
   * @type {IssueStatus}
   * @memberof IssueDto
   */
  issueStatus: IssueStatus
  /**
   *
   * @type {ObjectType}
   * @memberof IssueDto
   */
  objectType: ObjectType
  /**
   *
   * @type {number}
   * @memberof IssueDto
   */
  objectId: number
  /**
   *
   * @type {string}
   * @memberof IssueDto
   */
  notes?: string | null
  /**
   *
   * @type {Array<number>}
   * @memberof IssueDto
   */
  carrierVisitIds: Array<number>
}
/**
 *
 * @export
 * @enum {string}
 */

export const IssueStatus = {
  Unresolved: 'Unresolved',
  FollowUp: 'FollowUp',
  Resolved: 'Resolved',
} as const

export type IssueStatus = (typeof IssueStatus)[keyof typeof IssueStatus]

/**
 *
 * @export
 * @enum {string}
 */

export const IssueType = {
  Unknown: 'Unknown',
  WorkInstructionUnfinished: 'WorkInstructionUnfinished',
} as const

export type IssueType = (typeof IssueType)[keyof typeof IssueType]

/**
 *
 * @export
 * @interface JobAndYardBlockEquipmentAssignmentDto
 */
export interface JobAndYardBlockEquipmentAssignmentDto {
  /**
   *
   * @type {Array<YardBlockEquipmentAssignmentDto>}
   * @memberof JobAndYardBlockEquipmentAssignmentDto
   */
  yardBlockEquipmentAssignments: Array<YardBlockEquipmentAssignmentDto>
  /**
   *
   * @type {Array<JobDto>}
   * @memberof JobAndYardBlockEquipmentAssignmentDto
   */
  jobs: Array<JobDto>
}
/**
 *
 * @export
 * @interface JobDto
 */
export interface JobDto {
  /**
   *
   * @type {number}
   * @memberof JobDto
   */
  workInstructionId: number
  /**
   *
   * @type {JobDtoFrom}
   * @memberof JobDto
   */
  from: JobDtoFrom
  /**
   *
   * @type {JobDtoFrom}
   * @memberof JobDto
   */
  to: JobDtoFrom
  /**
   *
   * @type {JobDtoFrom}
   * @memberof JobDto
   */
  origin: JobDtoFrom
  /**
   *
   * @type {JobDtoFrom}
   * @memberof JobDto
   */
  destination: JobDtoFrom
  /**
   *
   * @type {OperationType}
   * @memberof JobDto
   */
  operationType: OperationType
  /**
   *
   * @type {WorkInstructionStatus}
   * @memberof JobDto
   */
  status: WorkInstructionStatus
  /**
   *
   * @type {number}
   * @memberof JobDto
   */
  originWorkAreaId?: number | null
  /**
   *
   * @type {number}
   * @memberof JobDto
   */
  destinationWorkAreaId?: number | null
  /**
   *
   * @type {number}
   * @memberof JobDto
   */
  workQueueId?: number | null
  /**
   *
   * @type {number}
   * @memberof JobDto
   */
  workQueueOrder?: number | null
  /**
   *
   * @type {string}
   * @memberof JobDto
   */
  deadline?: string | null
  /**
   *
   * @type {boolean}
   * @memberof JobDto
   */
  isPlanned: boolean
  /**
   *
   * @type {WorkInstructionWarningReason}
   * @memberof JobDto
   */
  warningReason?: WorkInstructionWarningReason | null
  /**
   *
   * @type {boolean}
   * @memberof JobDto
   */
  isOptional: boolean
  /**
   *
   * @type {GroupingDto}
   * @memberof JobDto
   */
  grouping?: GroupingDto | null
  /**
   *
   * @type {WorkInstructionJobCarrierVisitDto}
   * @memberof JobDto
   */
  carrierVisit?: WorkInstructionJobCarrierVisitDto | null
  /**
   *
   * @type {WorkInstructionCargoUnitDto}
   * @memberof JobDto
   */
  cargoUnit?: WorkInstructionCargoUnitDto | null
  /**
   *
   * @type {WorkInstructionJobOrderDto}
   * @memberof JobDto
   */
  order?: WorkInstructionJobOrderDto | null
  /**
   *
   * @type {boolean}
   * @memberof JobDto
   */
  hasCoolingOrder: boolean
  /**
   *
   * @type {number}
   * @memberof JobDto
   */
  requestedTemperature?: number | null
  /**
   *
   * @type {WorkInstructionJobServiceDto}
   * @memberof JobDto
   */
  serviceOrder?: WorkInstructionJobServiceDto | null
  /**
   *
   * @type {number}
   * @memberof JobDto
   */
  railcarTrackPositionId?: number | null
  /**
   *
   * @type {number}
   * @memberof JobDto
   */
  railcarId?: number | null
  /**
   *
   * @type {number}
   * @memberof JobDto
   */
  vesselBay?: number | null
  /**
   *
   * @type {WorkInstructionEquipmentDto}
   * @memberof JobDto
   */
  assignedEquipment?: WorkInstructionEquipmentDto | null
  /**
   *
   * @type {LinkedOutboundJobWorkInstruction}
   * @memberof JobDto
   */
  linkedOutboundWorkInstruction?: LinkedOutboundJobWorkInstruction | null
  /**
   *
   * @type {WorkInstructionWorkQueueDto}
   * @memberof JobDto
   */
  workQueue?: WorkInstructionWorkQueueDto | null
}
/**
 *
 * @export
 * @interface JobDtoFrom
 */
export interface JobDtoFrom {
  /**
   *
   * @type {string}
   * @memberof JobDtoFrom
   */
  locationName: string
  /**
   *
   * @type {ContainerPositionType}
   * @memberof JobDtoFrom
   */
  type: ContainerPositionType
  /**
   *
   * @type {string}
   * @memberof JobDtoFrom
   */
  yardBlockName: string
  /**
   *
   * @type {CarrierType}
   * @memberof JobDtoFrom
   */
  yardBlockType?: CarrierType | null
  /**
   *
   * @type {Array<string>}
   * @memberof JobDtoFrom
   */
  yardBays?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof JobDtoFrom
   */
  equipmentId?: number | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const LengthMeasurementUnit = {
  Meter: 'Meter',
  Centimeter: 'Centimeter',
  Millimeters: 'Millimeters',
  Feet: 'Feet',
  Inches: 'Inches',
} as const

export type LengthMeasurementUnit =
  (typeof LengthMeasurementUnit)[keyof typeof LengthMeasurementUnit]

/**
 *
 * @export
 * @interface LinkedOutboundJobWorkInstruction
 */
export interface LinkedOutboundJobWorkInstruction {
  /**
   *
   * @type {number}
   * @memberof LinkedOutboundJobWorkInstruction
   */
  workInstructionId: number
  /**
   *
   * @type {JobDtoFrom}
   * @memberof LinkedOutboundJobWorkInstruction
   */
  destination: JobDtoFrom
  /**
   *
   * @type {number}
   * @memberof LinkedOutboundJobWorkInstruction
   */
  railcarTrackPositionId?: number | null
  /**
   *
   * @type {number}
   * @memberof LinkedOutboundJobWorkInstruction
   */
  railcarId?: number | null
  /**
   *
   * @type {number}
   * @memberof LinkedOutboundJobWorkInstruction
   */
  destinationWorkAreaId?: number | null
  /**
   *
   * @type {WorkInstructionJobCarrierVisitDto}
   * @memberof LinkedOutboundJobWorkInstruction
   */
  carrierVisit?: WorkInstructionJobCarrierVisitDto | null
  /**
   *
   * @type {WorkInstructionJobOrderDto}
   * @memberof LinkedOutboundJobWorkInstruction
   */
  order?: WorkInstructionJobOrderDto | null
}
/**
 *
 * @export
 * @interface Location
 */
export interface Location {
  /**
   *
   * @type {ContainerPositionType}
   * @memberof Location
   */
  type: ContainerPositionType
  /**
   *
   * @type {string}
   * @memberof Location
   */
  yardBlockName?: string | null
  /**
   *
   * @type {CarrierType}
   * @memberof Location
   */
  yardBlockType?: CarrierType | null
  /**
   *
   * @type {Array<string>}
   * @memberof Location
   */
  yardBays?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof Location
   */
  locationName?: string | null
  /**
   *
   * @type {string}
   * @memberof Location
   */
  vesselLocationName?: string | null
  /**
   *
   * @type {number}
   * @memberof Location
   */
  equipmentId?: number | null
  /**
   *
   * @type {string}
   * @memberof Location
   */
  equipmentName?: string | null
  /**
   *
   * @type {EquipmentType}
   * @memberof Location
   */
  equipmentType?: EquipmentType | null
}
/**
 *
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
  /**
   *
   * @type {string}
   * @memberof LocationDto
   */
  locationName: string
  /**
   *
   * @type {ContainerPositionType}
   * @memberof LocationDto
   */
  type: ContainerPositionType
  /**
   *
   * @type {string}
   * @memberof LocationDto
   */
  yardBlockName: string
  /**
   *
   * @type {CarrierType}
   * @memberof LocationDto
   */
  yardBlockType?: CarrierType | null
  /**
   *
   * @type {Array<string>}
   * @memberof LocationDto
   */
  yardBays?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof LocationDto
   */
  equipmentId?: number | null
}
/**
 *
 * @export
 * @interface NonNumericInfoDto
 */
export interface NonNumericInfoDto {
  /**
   *
   * @type {number}
   * @memberof NonNumericInfoDto
   */
  length?: number | null
  /**
   *
   * @type {ContainerHeight}
   * @memberof NonNumericInfoDto
   */
  height?: ContainerHeight | null
  /**
   *
   * @type {ContainerType}
   * @memberof NonNumericInfoDto
   */
  type?: ContainerType | null
  /**
   *
   * @type {string}
   * @memberof NonNumericInfoDto
   */
  operator?: string | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const ObjectType = {
  Unknown: 'Unknown',
  WorkInstruction: 'WorkInstruction',
} as const

export type ObjectType = (typeof ObjectType)[keyof typeof ObjectType]

/**
 *
 * @export
 * @enum {string}
 */

export const OperationType = {
  Inbound: 'Inbound',
  Outbound: 'Outbound',
  Internal: 'Internal',
  Service: 'Service',
  Restow: 'Restow',
} as const

export type OperationType = (typeof OperationType)[keyof typeof OperationType]

/**
 *
 * @export
 * @enum {string}
 */

export const OperatorFields = {
  Process: 'PROCESS',
  ReferenceNumber: 'REFERENCE_NUMBER',
  Sequence: 'SEQUENCE',
  State: 'STATE',
  ImoClasses: 'IMO_CLASSES',
  Weight: 'WEIGHT',
  Iso: 'ISO',
  Length: 'LENGTH',
  Height: 'HEIGHT',
  Type: 'TYPE',
  Temperature: 'TEMPERATURE',
  Carrier: 'CARRIER',
  Operator: 'OPERATOR',
  Pod: 'POD',
  TransportMode: 'TRANSPORT_MODE',
  Consignee: 'CONSIGNEE',
} as const

export type OperatorFields = (typeof OperatorFields)[keyof typeof OperatorFields]

/**
 *
 * @export
 * @interface OperatorFieldsSettings
 */
export interface OperatorFieldsSettings {
  /**
   *
   * @type {OperatorFields}
   * @memberof OperatorFieldsSettings
   */
  fields: OperatorFields
  /**
   *
   * @type {Array<OperationType>}
   * @memberof OperatorFieldsSettings
   */
  operationTypes: Array<OperationType>
  /**
   *
   * @type {Array<CarrierType>}
   * @memberof OperatorFieldsSettings
   */
  carrierTypes: Array<CarrierType>
  /**
   *
   * @type {string}
   * @memberof OperatorFieldsSettings
   */
  serializedOperatorFieldsSettings: string
}
/**
 *
 * @export
 * @interface OperatorViewSettings
 */
export interface OperatorViewSettings {
  /**
   *
   * @type {boolean}
   * @memberof OperatorViewSettings
   */
  displayVesselVisitSelector: boolean
  /**
   *
   * @type {boolean}
   * @memberof OperatorViewSettings
   */
  displayOperationsTypeSelector: boolean
  /**
   *
   * @type {Array<OperatorFieldsSettings>}
   * @memberof OperatorViewSettings
   */
  operatorFields: Array<OperatorFieldsSettings>
  /**
   *
   * @type {string}
   * @memberof OperatorViewSettings
   */
  serializedOperatorViewSettings: string
}
/**
 *
 * @export
 * @interface OptionDto
 */
export interface OptionDto {
  /**
   *
   * @type {number}
   * @memberof OptionDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof OptionDto
   */
  name: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const OrderStatus = {
  Open: 'Open',
  Fulfilled: 'Fulfilled',
} as const

export type OrderStatus = (typeof OrderStatus)[keyof typeof OrderStatus]

/**
 *
 * @export
 * @interface RailcarTrackPositionDto
 */
export interface RailcarTrackPositionDto {
  /**
   *
   * @type {number}
   * @memberof RailcarTrackPositionDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof RailcarTrackPositionDto
   */
  railVisitId: number
  /**
   *
   * @type {string}
   * @memberof RailcarTrackPositionDto
   */
  railTrackId?: string | null
  /**
   *
   * @type {number}
   * @memberof RailcarTrackPositionDto
   */
  railcarId?: number | null
  /**
   *
   * @type {string}
   * @memberof RailcarTrackPositionDto
   */
  railcarName: string
  /**
   *
   * @type {number}
   * @memberof RailcarTrackPositionDto
   */
  railcarSequenceNumber?: number | null
  /**
   *
   * @type {RailcarTrackPositionStatus}
   * @memberof RailcarTrackPositionDto
   */
  status: RailcarTrackPositionStatus
  /**
   *
   * @type {number}
   * @memberof RailcarTrackPositionDto
   */
  totalGrossWeight?: number | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const RailcarTrackPositionStatus = {
  Todo: 'Todo',
  InProgress: 'InProgress',
  CheckedOut: 'CheckedOut',
} as const

export type RailcarTrackPositionStatus =
  (typeof RailcarTrackPositionStatus)[keyof typeof RailcarTrackPositionStatus]

/**
 *
 * @export
 * @interface ReeferTemperatureDto
 */
export interface ReeferTemperatureDto {
  /**
   *
   * @type {number}
   * @memberof ReeferTemperatureDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof ReeferTemperatureDto
   */
  cargoUnitId: number
  /**
   *
   * @type {number}
   * @memberof ReeferTemperatureDto
   */
  temperature?: number | null
  /**
   *
   * @type {number}
   * @memberof ReeferTemperatureDto
   */
  humidity?: number | null
  /**
   *
   * @type {number}
   * @memberof ReeferTemperatureDto
   */
  ventilation?: number | null
  /**
   *
   * @type {boolean}
   * @memberof ReeferTemperatureDto
   */
  isPluggedIn: boolean
  /**
   *
   * @type {string}
   * @memberof ReeferTemperatureDto
   */
  recordedAt: string
  /**
   *
   * @type {string}
   * @memberof ReeferTemperatureDto
   */
  additionalInfo?: string | null
}
/**
 *
 * @export
 * @interface SaveRequest
 */
export interface SaveRequest {
  /**
   *
   * @type {number}
   * @memberof SaveRequest
   */
  carrierVisitId: number
  /**
   *
   * @type {Array<WorkQueueActionDto>}
   * @memberof SaveRequest
   */
  workQueueActions: Array<WorkQueueActionDto>
}
/**
 *
 * @export
 * @interface SaveWorkQueueCommand
 */
export interface SaveWorkQueueCommand {
  /**
   *
   * @type {number}
   * @memberof SaveWorkQueueCommand
   */
  carrierVisitId: number
  /**
   *
   * @type {Array<WorkQueueActionDto>}
   * @memberof SaveWorkQueueCommand
   */
  workQueueActions: Array<WorkQueueActionDto>
}
/**
 *
 * @export
 * @interface StartJobCommand
 */
export interface StartJobCommand {
  /**
   *
   * @type {number}
   * @memberof StartJobCommand
   */
  workInstructionId: number
  /**
   *
   * @type {number}
   * @memberof StartJobCommand
   */
  equipmentId: number
}
/**
 *
 * @export
 * @interface StartJobRequest
 */
export interface StartJobRequest {
  /**
   *
   * @type {number}
   * @memberof StartJobRequest
   */
  workInstructionId: number
  /**
   *
   * @type {number}
   * @memberof StartJobRequest
   */
  equipmentId: number
}
/**
 *
 * @export
 * @interface TallymanAssignEquipmentToJobCommand
 */
export interface TallymanAssignEquipmentToJobCommand {
  /**
   *
   * @type {number}
   * @memberof TallymanAssignEquipmentToJobCommand
   */
  equipmentId: number
  /**
   *
   * @type {number}
   * @memberof TallymanAssignEquipmentToJobCommand
   */
  carrierVisitId: number
  /**
   *
   * @type {number}
   * @memberof TallymanAssignEquipmentToJobCommand
   */
  orderId: number
}
/**
 *
 * @export
 * @interface TenantConfigDto
 */
export interface TenantConfigDto {
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  skipCraneSplit: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  skipVesselTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  skipRailTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  showUnifiedOperatorPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  skipYardPlanning: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigDto
   */
  allowOperatorToSelectRailcar: boolean
  /**
   *
   * @type {number}
   * @memberof TenantConfigDto
   */
  reeferContainerMaxUnpluggedTime?: number | null
  /**
   *
   * @type {{ [key: string]: OperatorViewSettings; }}
   * @memberof TenantConfigDto
   */
  operatorViewSettings: { [key: string]: OperatorViewSettings }
}
/**
 *
 * @export
 * @enum {string}
 */

export const TenantConfigKey = {
  SkipCraneSplit: 'SkipCraneSplit',
} as const

export type TenantConfigKey = (typeof TenantConfigKey)[keyof typeof TenantConfigKey]

/**
 *
 * @export
 * @interface UnassignEquipmentCommand
 */
export interface UnassignEquipmentCommand {
  /**
   *
   * @type {number}
   * @memberof UnassignEquipmentCommand
   */
  equipmentId: number
  /**
   *
   * @type {number}
   * @memberof UnassignEquipmentCommand
   */
  workingAreaId: number
  /**
   *
   * @type {WorkAreaEquipmentBindingTypes}
   * @memberof UnassignEquipmentCommand
   */
  assignmentType: WorkAreaEquipmentBindingTypes
}
/**
 *
 * @export
 * @interface UnassignRequest
 */
export interface UnassignRequest {
  /**
   *
   * @type {number}
   * @memberof UnassignRequest
   */
  equipmentId: number
  /**
   *
   * @type {number}
   * @memberof UnassignRequest
   */
  workingAreaId: number
  /**
   *
   * @type {WorkAreaEquipmentBindingTypes}
   * @memberof UnassignRequest
   */
  assignmentType: WorkAreaEquipmentBindingTypes
}
/**
 *
 * @export
 * @interface UpdateEquipmentCommand
 */
export interface UpdateEquipmentCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateEquipmentCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateEquipmentCommand
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof UpdateEquipmentCommand
   */
  maxWeight?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateEquipmentCommand
   */
  maxHeight?: number | null
  /**
   *
   * @type {Array<YardBlockAssignmentDto>}
   * @memberof UpdateEquipmentCommand
   */
  yardBlockAssignments: Array<YardBlockAssignmentDto>
  /**
   *
   * @type {boolean}
   * @memberof UpdateEquipmentCommand
   */
  isOnMaintenance: boolean
}
/**
 *
 * @export
 * @interface UpdateReeferTemperatureCommand
 */
export interface UpdateReeferTemperatureCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateReeferTemperatureCommand
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof UpdateReeferTemperatureCommand
   */
  temperature?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateReeferTemperatureCommand
   */
  humidity?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateReeferTemperatureCommand
   */
  ventilation?: number | null
  /**
   *
   * @type {boolean}
   * @memberof UpdateReeferTemperatureCommand
   */
  isPluggedIn: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateReeferTemperatureCommand
   */
  recordedAt: string
  /**
   *
   * @type {string}
   * @memberof UpdateReeferTemperatureCommand
   */
  additionalInfo?: string | null
}
/**
 *
 * @export
 * @interface UpdateRequest
 */
export interface UpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateRequest
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateRequest
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof UpdateRequest
   */
  maxWeight?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateRequest
   */
  maxHeight?: number | null
  /**
   *
   * @type {Array<YardBlockAssignmentDto>}
   * @memberof UpdateRequest
   */
  yardBlockAssignments: Array<YardBlockAssignmentDto>
  /**
   *
   * @type {boolean}
   * @memberof UpdateRequest
   */
  isOnMaintenance: boolean
}
/**
 *
 * @export
 * @interface UpdateRequest1
 */
export interface UpdateRequest1 {
  /**
   *
   * @type {number}
   * @memberof UpdateRequest1
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof UpdateRequest1
   */
  temperature?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateRequest1
   */
  humidity?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateRequest1
   */
  ventilation?: number | null
  /**
   *
   * @type {boolean}
   * @memberof UpdateRequest1
   */
  isPluggedIn: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateRequest1
   */
  recordedAt: string
  /**
   *
   * @type {string}
   * @memberof UpdateRequest1
   */
  additionalInfo?: string | null
}
/**
 *
 * @export
 * @interface UploadConfirmationSignatureCommand
 */
export interface UploadConfirmationSignatureCommand {
  /**
   *
   * @type {string}
   * @memberof UploadConfirmationSignatureCommand
   */
  operatorSignature: string
  /**
   *
   * @type {string}
   * @memberof UploadConfirmationSignatureCommand
   */
  driverSignature?: string | null
  /**
   *
   * @type {number}
   * @memberof UploadConfirmationSignatureCommand
   */
  carrierVisitId: number
  /**
   *
   * @type {string}
   * @memberof UploadConfirmationSignatureCommand
   */
  operatorName?: string | null
}
/**
 *
 * @export
 * @interface UploadSignaturesRequest
 */
export interface UploadSignaturesRequest {
  /**
   *
   * @type {string}
   * @memberof UploadSignaturesRequest
   */
  operatorSignature: string
  /**
   *
   * @type {string}
   * @memberof UploadSignaturesRequest
   */
  driverSignature?: string | null
  /**
   *
   * @type {number}
   * @memberof UploadSignaturesRequest
   */
  carrierVisitId: number
  /**
   *
   * @type {string}
   * @memberof UploadSignaturesRequest
   */
  operatorName?: string | null
}
/**
 *
 * @export
 * @interface UpsertRequest
 */
export interface UpsertRequest {
  /**
   *
   * @type {TenantConfigKey}
   * @memberof UpsertRequest
   */
  key: TenantConfigKey
  /**
   *
   * @type {string}
   * @memberof UpsertRequest
   */
  stringValue?: string | null
}
/**
 *
 * @export
 * @interface UpsertTenantConfigCommand
 */
export interface UpsertTenantConfigCommand {
  /**
   *
   * @type {TenantConfigKey}
   * @memberof UpsertTenantConfigCommand
   */
  key: TenantConfigKey
  /**
   *
   * @type {string}
   * @memberof UpsertTenantConfigCommand
   */
  stringValue?: string | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const VesselArea = {
  Hold: 'Hold',
  Deck: 'Deck',
} as const

export type VesselArea = (typeof VesselArea)[keyof typeof VesselArea]

/**
 *
 * @export
 * @interface VesselBayDto
 */
export interface VesselBayDto {
  /**
   *
   * @type {number}
   * @memberof VesselBayDto
   */
  bay: number
  /**
   *
   * @type {VesselBayType}
   * @memberof VesselBayDto
   */
  type: VesselBayType
  /**
   *
   * @type {boolean}
   * @memberof VesselBayDto
   */
  hasZeroRow: boolean
  /**
   *
   * @type {number}
   * @memberof VesselBayDto
   */
  maxRow: number
  /**
   *
   * @type {number}
   * @memberof VesselBayDto
   */
  minTier: number
  /**
   *
   * @type {number}
   * @memberof VesselBayDto
   */
  maxTier: number
  /**
   *
   * @type {number}
   * @memberof VesselBayDto
   */
  deckHeight: number
  /**
   *
   * @type {number}
   * @memberof VesselBayDto
   */
  deckStartedAt: number
  /**
   *
   * @type {Array<VesselBaySlotDto>}
   * @memberof VesselBayDto
   */
  holdSlots: Array<VesselBaySlotDto>
  /**
   *
   * @type {Array<VesselBaySlotDto>}
   * @memberof VesselBayDto
   */
  deckSlots: Array<VesselBaySlotDto>
}
/**
 *
 * @export
 * @interface VesselBaySlotDto
 */
export interface VesselBaySlotDto {
  /**
   *
   * @type {number}
   * @memberof VesselBaySlotDto
   */
  row: number
  /**
   *
   * @type {number}
   * @memberof VesselBaySlotDto
   */
  tier: number
  /**
   *
   * @type {boolean}
   * @memberof VesselBaySlotDto
   */
  isHull: boolean
  /**
   *
   * @type {ContainerSlotDto}
   * @memberof VesselBaySlotDto
   */
  inboundContainer?: ContainerSlotDto | null
  /**
   *
   * @type {ContainerSlotDto}
   * @memberof VesselBaySlotDto
   */
  outboundContainer?: ContainerSlotDto | null
  /**
   *
   * @type {ContainerSlotDto}
   * @memberof VesselBaySlotDto
   */
  remainOnBoardContainer?: ContainerSlotDto | null
  /**
   *
   * @type {ContainerSlotDto}
   * @memberof VesselBaySlotDto
   */
  nonNumeric?: ContainerSlotDto | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const VesselBayType = {
  TwentySingle: 'TWENTY_SINGLE',
  TwentyFront: 'TWENTY_FRONT',
  TwentyBack: 'TWENTY_BACK',
  Forty: 'FORTY',
} as const

export type VesselBayType = (typeof VesselBayType)[keyof typeof VesselBayType]

/**
 *
 * @export
 * @interface VesselGeometryDto
 */
export interface VesselGeometryDto {
  /**
   *
   * @type {number}
   * @memberof VesselGeometryDto
   */
  firstBay: number
  /**
   *
   * @type {number}
   * @memberof VesselGeometryDto
   */
  lastBay: number
  /**
   *
   * @type {Array<VesselBayDto>}
   * @memberof VesselGeometryDto
   */
  bays: Array<VesselBayDto>
  /**
   *
   * @type {Array<string>}
   * @memberof VesselGeometryDto
   */
  portsToDischarge: Array<string>
}
/**
 *
 * @export
 * @enum {string}
 */

export const WeightMeasurementUnit = {
  Kilogram: 'Kilogram',
  Ton: 'Ton',
} as const

export type WeightMeasurementUnit =
  (typeof WeightMeasurementUnit)[keyof typeof WeightMeasurementUnit]

/**
 *
 * @export
 * @interface WorkAreaDto
 */
export interface WorkAreaDto {
  /**
   *
   * @type {number}
   * @memberof WorkAreaDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof WorkAreaDto
   */
  name: string
  /**
   *
   * @type {CarrierVisitDto}
   * @memberof WorkAreaDto
   */
  carrierVisit?: CarrierVisitDto | null
  /**
   *
   * @type {WorkAreaType}
   * @memberof WorkAreaDto
   */
  workAreaType: WorkAreaType
  /**
   *
   * @type {string}
   * @memberof WorkAreaDto
   */
  validFrom?: string | null
  /**
   *
   * @type {string}
   * @memberof WorkAreaDto
   */
  validTo?: string | null
  /**
   *
   * @type {Array<WorkInstructionDto>}
   * @memberof WorkAreaDto
   */
  originWorkInstructions: Array<WorkInstructionDto>
  /**
   *
   * @type {Array<WorkInstructionDto>}
   * @memberof WorkAreaDto
   */
  destinationWorkInstructions: Array<WorkInstructionDto>
  /**
   *
   * @type {Array<OptionDto>}
   * @memberof WorkAreaDto
   */
  equipments: Array<OptionDto>
  /**
   *
   * @type {Array<WorkAreaEquipmentDto>}
   * @memberof WorkAreaDto
   */
  workAreaEquipments: Array<WorkAreaEquipmentDto>
  /**
   *
   * @type {EquipmentDto}
   * @memberof WorkAreaDto
   */
  crane?: EquipmentDto | null
  /**
   *
   * @type {EquipmentDto}
   * @memberof WorkAreaDto
   */
  rtg?: EquipmentDto | null
  /**
   *
   * @type {Array<YardBlockDto>}
   * @memberof WorkAreaDto
   */
  yardBlocks: Array<YardBlockDto>
}
/**
 *
 * @export
 * @enum {string}
 */

export const WorkAreaEquipmentBindingTypes = {
  None: 'None',
  Discharge: 'Discharge',
  Load: 'Load',
} as const

export type WorkAreaEquipmentBindingTypes =
  (typeof WorkAreaEquipmentBindingTypes)[keyof typeof WorkAreaEquipmentBindingTypes]

/**
 *
 * @export
 * @interface WorkAreaEquipmentDto
 */
export interface WorkAreaEquipmentDto {
  /**
   *
   * @type {number}
   * @memberof WorkAreaEquipmentDto
   */
  workAreaId: number
  /**
   *
   * @type {number}
   * @memberof WorkAreaEquipmentDto
   */
  equipmentId: number
  /**
   *
   * @type {WorkAreaEquipmentBindingTypes}
   * @memberof WorkAreaEquipmentDto
   */
  assignmentType: WorkAreaEquipmentBindingTypes
}
/**
 *
 * @export
 * @enum {string}
 */

export const WorkAreaType = {
  Yard: 'Yard',
  Crane: 'Crane',
  Train: 'Train',
} as const

export type WorkAreaType = (typeof WorkAreaType)[keyof typeof WorkAreaType]

/**
 *
 * @export
 * @interface WorkInstructionCargoUnitDto
 */
export interface WorkInstructionCargoUnitDto {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionCargoUnitDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof WorkInstructionCargoUnitDto
   */
  displayName?: string | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionCargoUnitDto
   */
  containerIsoCode?: string | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionCargoUnitDto
   */
  operator?: string | null
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionCargoUnitDto
   */
  isEmpty: boolean
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionCargoUnitDto
   */
  isDamaged: boolean
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionCargoUnitDto
   */
  isReefer: boolean
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionCargoUnitDto
   */
  isPluggedIn?: boolean | null
  /**
   *
   * @type {number}
   * @memberof WorkInstructionCargoUnitDto
   */
  length: number
  /**
   *
   * @type {ContainerHeight}
   * @memberof WorkInstructionCargoUnitDto
   */
  height?: ContainerHeight | null
  /**
   *
   * @type {ContainerType}
   * @memberof WorkInstructionCargoUnitDto
   */
  type: ContainerType
  /**
   *
   * @type {string}
   * @memberof WorkInstructionCargoUnitDto
   */
  portOfDischarge?: string | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionCargoUnitDto
   */
  plannedDischargeYardBlockId?: string | null
  /**
   *
   * @type {CarrierType}
   * @memberof WorkInstructionCargoUnitDto
   */
  outboundCarrierType?: CarrierType | null
  /**
   *
   * @type {JobDtoFrom}
   * @memberof WorkInstructionCargoUnitDto
   */
  currentLocation: JobDtoFrom
}
/**
 *
 * @export
 * @interface WorkInstructionCommodityDto
 */
export interface WorkInstructionCommodityDto {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionCommodityDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof WorkInstructionCommodityDto
   */
  name: string
}
/**
 *
 * @export
 * @interface WorkInstructionDto
 */
export interface WorkInstructionDto {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof WorkInstructionDto
   */
  carrierVisitId: number
  /**
   *
   * @type {CarrierType}
   * @memberof WorkInstructionDto
   */
  carrierVisitType: CarrierType
  /**
   *
   * @type {string}
   * @memberof WorkInstructionDto
   */
  origin?: string | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionDto
   */
  destination?: string | null
  /**
   *
   * @type {OperationType}
   * @memberof WorkInstructionDto
   */
  operationType: OperationType
  /**
   *
   * @type {WorkInstructionStatus}
   * @memberof WorkInstructionDto
   */
  status: WorkInstructionStatus
  /**
   *
   * @type {number}
   * @memberof WorkInstructionDto
   */
  externalOrderId: number
  /**
   *
   * @type {string}
   * @memberof WorkInstructionDto
   */
  externalContainerNumber: string
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionDto
   */
  isConfirmed: boolean
  /**
   *
   * @type {number}
   * @memberof WorkInstructionDto
   */
  originWorkAreaId: number
  /**
   *
   * @type {number}
   * @memberof WorkInstructionDto
   */
  destinationWorkAreaId?: number | null
  /**
   *
   * @type {number}
   * @memberof WorkInstructionDto
   */
  workQueueId?: number | null
  /**
   *
   * @type {number}
   * @memberof WorkInstructionDto
   */
  workQueueOrder?: number | null
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionDto
   */
  ignoreInCraneSplit: boolean
  /**
   *
   * @type {number}
   * @memberof WorkInstructionDto
   */
  containerStowageLocationId?: number | null
  /**
   *
   * @type {CargoUnitDto}
   * @memberof WorkInstructionDto
   */
  cargoUnit?: CargoUnitDto | null
  /**
   *
   * @type {Array<WorkInstructionListJobDto>}
   * @memberof WorkInstructionDto
   */
  jobs?: Array<WorkInstructionListJobDto> | null
}
/**
 *
 * @export
 * @interface WorkInstructionEquipmentDto
 */
export interface WorkInstructionEquipmentDto {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionEquipmentDto
   */
  id?: number | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionEquipmentDto
   */
  name?: string | null
  /**
   *
   * @type {EquipmentType}
   * @memberof WorkInstructionEquipmentDto
   */
  equipmentType?: EquipmentType | null
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionEquipmentDto
   */
  isPlanned: boolean
}
/**
 *
 * @export
 * @interface WorkInstructionForContainerSlotDto
 */
export interface WorkInstructionForContainerSlotDto {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionForContainerSlotDto
   */
  id: number
  /**
   *
   * @type {OperationType}
   * @memberof WorkInstructionForContainerSlotDto
   */
  operationType: OperationType
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionForContainerSlotDto
   */
  confirmed: boolean
  /**
   *
   * @type {number}
   * @memberof WorkInstructionForContainerSlotDto
   */
  craneId?: number | null
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionForContainerSlotDto
   */
  hasActiveHolds: boolean
}
/**
 *
 * @export
 * @interface WorkInstructionJobCargoUnitDto
 */
export interface WorkInstructionJobCargoUnitDto {
  /**
   *
   * @type {WorkInstructionJobCargoUnitDtoCommodity}
   * @memberof WorkInstructionJobCargoUnitDto
   */
  commodity: WorkInstructionJobCargoUnitDtoCommodity
  /**
   *
   * @type {WorkInstructionPackageDto}
   * @memberof WorkInstructionJobCargoUnitDto
   */
  package?: WorkInstructionPackageDto | null
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobCargoUnitDto
   */
  externalId?: number | null
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobCargoUnitDto
   */
  amount: number
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobCargoUnitDto
   */
  completedAmount: number
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobCargoUnitDto
   */
  lotNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobCargoUnitDto
   */
  handlingInstructions?: string | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobCargoUnitDto
   */
  operator?: string | null
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionJobCargoUnitDto
   */
  isDamaged: boolean
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof WorkInstructionJobCargoUnitDto
   */
  weightUnit?: WeightMeasurementUnit | null
}
/**
 *
 * @export
 * @interface WorkInstructionJobCargoUnitDtoCommodity
 */
export interface WorkInstructionJobCargoUnitDtoCommodity {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobCargoUnitDtoCommodity
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobCargoUnitDtoCommodity
   */
  name: string
}
/**
 *
 * @export
 * @interface WorkInstructionJobCarrierVisitDto
 */
export interface WorkInstructionJobCarrierVisitDto {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobCarrierVisitDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobCarrierVisitDto
   */
  carrierName?: string | null
  /**
   *
   * @type {CarrierType}
   * @memberof WorkInstructionJobCarrierVisitDto
   */
  type: CarrierType
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobCarrierVisitDto
   */
  ata?: string | null
}
/**
 *
 * @export
 * @interface WorkInstructionJobDto
 */
export interface WorkInstructionJobDto {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobDto
   */
  workInstructionId: number
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobDto
   */
  displayName: string
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobDto
   */
  origin: string
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobDto
   */
  destination: string
  /**
   *
   * @type {OperationType}
   * @memberof WorkInstructionJobDto
   */
  operationType: OperationType
  /**
   *
   * @type {WorkInstructionJobCarrierVisitDto}
   * @memberof WorkInstructionJobDto
   */
  carrierVisit?: WorkInstructionJobCarrierVisitDto | null
  /**
   *
   * @type {WorkInstructionJobOrderDto}
   * @memberof WorkInstructionJobDto
   */
  order?: WorkInstructionJobOrderDto | null
  /**
   *
   * @type {WorkInstructionJobServiceDto}
   * @memberof WorkInstructionJobDto
   */
  service?: WorkInstructionJobServiceDto | null
  /**
   *
   * @type {WorkInstructionJobDtoCargoUnit}
   * @memberof WorkInstructionJobDto
   */
  cargoUnit: WorkInstructionJobDtoCargoUnit
}
/**
 *
 * @export
 * @interface WorkInstructionJobDtoCargoUnit
 */
export interface WorkInstructionJobDtoCargoUnit {
  /**
   *
   * @type {WorkInstructionJobCargoUnitDtoCommodity}
   * @memberof WorkInstructionJobDtoCargoUnit
   */
  commodity: WorkInstructionJobCargoUnitDtoCommodity
  /**
   *
   * @type {WorkInstructionPackageDto}
   * @memberof WorkInstructionJobDtoCargoUnit
   */
  package?: WorkInstructionPackageDto | null
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobDtoCargoUnit
   */
  externalId?: number | null
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobDtoCargoUnit
   */
  amount: number
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobDtoCargoUnit
   */
  completedAmount: number
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobDtoCargoUnit
   */
  lotNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobDtoCargoUnit
   */
  handlingInstructions?: string | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobDtoCargoUnit
   */
  operator?: string | null
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionJobDtoCargoUnit
   */
  isDamaged: boolean
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof WorkInstructionJobDtoCargoUnit
   */
  weightUnit?: WeightMeasurementUnit | null
}
/**
 *
 * @export
 * @interface WorkInstructionJobOrderDto
 */
export interface WorkInstructionJobOrderDto {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobOrderDto
   */
  externalId?: number | null
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobOrderDto
   */
  customerId?: number | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobOrderDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobOrderDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobOrderDto
   */
  consignee?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof WorkInstructionJobOrderDto
   */
  imoClasses: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionJobOrderDto
   */
  isDangerous: boolean
  /**
   *
   * @type {DoorDirection}
   * @memberof WorkInstructionJobOrderDto
   */
  doorDirection?: DoorDirection | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionJobOrderDto
   */
  operationalInstructions?: string | null
  /**
   *
   * @type {boolean}
   * @memberof WorkInstructionJobOrderDto
   */
  isOOG: boolean
}
/**
 *
 * @export
 * @interface WorkInstructionJobServiceDto
 */
export interface WorkInstructionJobServiceDto {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionJobServiceDto
   */
  externalId?: number | null
  /**
   *
   * @type {ContainerService}
   * @memberof WorkInstructionJobServiceDto
   */
  type?: ContainerService | null
}
/**
 *
 * @export
 * @interface WorkInstructionListJobDto
 */
export interface WorkInstructionListJobDto {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionListJobDto
   */
  id: number
  /**
   *
   * @type {JobDtoFrom}
   * @memberof WorkInstructionListJobDto
   */
  from: JobDtoFrom
  /**
   *
   * @type {JobDtoFrom}
   * @memberof WorkInstructionListJobDto
   */
  to: JobDtoFrom
  /**
   *
   * @type {EquipmentType}
   * @memberof WorkInstructionListJobDto
   */
  equipmentType?: EquipmentType | null
  /**
   *
   * @type {string}
   * @memberof WorkInstructionListJobDto
   */
  equipmentName?: string | null
  /**
   *
   * @type {number}
   * @memberof WorkInstructionListJobDto
   */
  equipmentId?: number | null
}
/**
 *
 * @export
 * @interface WorkInstructionPackageDto
 */
export interface WorkInstructionPackageDto {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionPackageDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof WorkInstructionPackageDto
   */
  name: string
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof WorkInstructionPackageDto
   */
  weightUnit: WeightMeasurementUnit
  /**
   *
   * @type {number}
   * @memberof WorkInstructionPackageDto
   */
  height?: number | null
  /**
   *
   * @type {number}
   * @memberof WorkInstructionPackageDto
   */
  width?: number | null
  /**
   *
   * @type {number}
   * @memberof WorkInstructionPackageDto
   */
  length?: number | null
  /**
   *
   * @type {LengthMeasurementUnit}
   * @memberof WorkInstructionPackageDto
   */
  lengthUnit?: LengthMeasurementUnit | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const WorkInstructionStatus = {
  Todo: 'Todo',
  InProgress: 'InProgress',
  Finished: 'Finished',
  WaitingForStackIn: 'WaitingForStackIn',
} as const

export type WorkInstructionStatus =
  (typeof WorkInstructionStatus)[keyof typeof WorkInstructionStatus]

/**
 *
 * @export
 * @enum {string}
 */

export const WorkInstructionWarningReason = {
  TallyNotConfirmed: 'TallyNotConfirmed',
  LinkedInboundNotFinished: 'LinkedInboundNotFinished',
  CarrierNotReadyForOperation: 'CarrierNotReadyForOperation',
  OnYardWithoutPosition: 'OnYardWithoutPosition',
  StuffingAndContainerCriticallyDamaged: 'StuffingAndContainerCriticallyDamaged',
  RequiresReservation: 'RequiresReservation',
  FutureOutbound: 'FutureOutbound',
  RequiresPlugOut: 'RequiresPlugOut',
  LinkedInboundForceFinishedByGateOutWithoutLocation:
    'LinkedInboundForceFinishedByGateOutWithoutLocation',
} as const

export type WorkInstructionWarningReason =
  (typeof WorkInstructionWarningReason)[keyof typeof WorkInstructionWarningReason]

/**
 *
 * @export
 * @interface WorkInstructionWorkQueueDto
 */
export interface WorkInstructionWorkQueueDto {
  /**
   *
   * @type {number}
   * @memberof WorkInstructionWorkQueueDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof WorkInstructionWorkQueueDto
   */
  order: number
}
/**
 *
 * @export
 * @interface WorkQueueActionDto
 */
export interface WorkQueueActionDto {
  /**
   *
   * @type {WorkQueueActionType}
   * @memberof WorkQueueActionDto
   */
  action: WorkQueueActionType
  /**
   *
   * @type {WorkQueueActionDtoWorkQueue}
   * @memberof WorkQueueActionDto
   */
  workQueue: WorkQueueActionDtoWorkQueue
}
/**
 *
 * @export
 * @interface WorkQueueActionDtoWorkQueue
 */
export interface WorkQueueActionDtoWorkQueue {
  /**
   *
   * @type {number}
   * @memberof WorkQueueActionDtoWorkQueue
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof WorkQueueActionDtoWorkQueue
   */
  bay: number
  /**
   *
   * @type {number}
   * @memberof WorkQueueActionDtoWorkQueue
   */
  order: number
  /**
   *
   * @type {OperationType}
   * @memberof WorkQueueActionDtoWorkQueue
   */
  operationType: OperationType
  /**
   *
   * @type {VesselArea}
   * @memberof WorkQueueActionDtoWorkQueue
   */
  vesselArea: VesselArea
  /**
   *
   * @type {number}
   * @memberof WorkQueueActionDtoWorkQueue
   */
  containersAmount: number
  /**
   *
   * @type {boolean}
   * @memberof WorkQueueActionDtoWorkQueue
   */
  isTwinContainers: boolean
  /**
   *
   * @type {number}
   * @memberof WorkQueueActionDtoWorkQueue
   */
  moves: number
  /**
   *
   * @type {number}
   * @memberof WorkQueueActionDtoWorkQueue
   */
  processingTimeSeconds: number
  /**
   *
   * @type {string}
   * @memberof WorkQueueActionDtoWorkQueue
   */
  startTime: string
  /**
   *
   * @type {number}
   * @memberof WorkQueueActionDtoWorkQueue
   */
  craneId: number
  /**
   *
   * @type {Array<number>}
   * @memberof WorkQueueActionDtoWorkQueue
   */
  workInstructionIds: Array<number>
}
/**
 *
 * @export
 * @enum {string}
 */

export const WorkQueueActionType = {
  Add: 'Add',
  Edit: 'Edit',
  Delete: 'Delete',
} as const

export type WorkQueueActionType = (typeof WorkQueueActionType)[keyof typeof WorkQueueActionType]

/**
 *
 * @export
 * @interface WorkQueueDto
 */
export interface WorkQueueDto {
  /**
   *
   * @type {number}
   * @memberof WorkQueueDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof WorkQueueDto
   */
  carrierVisitId: number
  /**
   *
   * @type {number}
   * @memberof WorkQueueDto
   */
  workAreaId: number
  /**
   *
   * @type {number}
   * @memberof WorkQueueDto
   */
  craneId: number
  /**
   *
   * @type {boolean}
   * @memberof WorkQueueDto
   */
  isFirstWorkQueue: boolean
  /**
   *
   * @type {number}
   * @memberof WorkQueueDto
   */
  nextWorkQueueId?: number | null
  /**
   *
   * @type {number}
   * @memberof WorkQueueDto
   */
  bay: number
  /**
   *
   * @type {VesselBayType}
   * @memberof WorkQueueDto
   */
  bayType?: VesselBayType | null
  /**
   *
   * @type {number}
   * @memberof WorkQueueDto
   */
  order: number
  /**
   *
   * @type {OperationType}
   * @memberof WorkQueueDto
   */
  operationType: OperationType
  /**
   *
   * @type {VesselArea}
   * @memberof WorkQueueDto
   */
  vesselArea: VesselArea
  /**
   *
   * @type {number}
   * @memberof WorkQueueDto
   */
  containersAmount: number
  /**
   *
   * @type {number}
   * @memberof WorkQueueDto
   */
  finishedWorkInstructionsAmount: number
  /**
   *
   * @type {number}
   * @memberof WorkQueueDto
   */
  processingTimeSeconds: number
  /**
   *
   * @type {number}
   * @memberof WorkQueueDto
   */
  moves: number
  /**
   *
   * @type {boolean}
   * @memberof WorkQueueDto
   */
  isTwinContainers: boolean
  /**
   *
   * @type {string}
   * @memberof WorkQueueDto
   */
  startTime: string
}
/**
 *
 * @export
 * @interface WorkQueueInfoDto
 */
export interface WorkQueueInfoDto {
  /**
   *
   * @type {number}
   * @memberof WorkQueueInfoDto
   */
  bay: number
  /**
   *
   * @type {OperationType}
   * @memberof WorkQueueInfoDto
   */
  operationType: OperationType
  /**
   *
   * @type {VesselArea}
   * @memberof WorkQueueInfoDto
   */
  vesselArea: VesselArea
}
/**
 *
 * @export
 * @interface WorkQueueItem
 */
export interface WorkQueueItem {
  /**
   *
   * @type {string}
   * @memberof WorkQueueItem
   */
  containerNumber: string
  /**
   *
   * @type {string}
   * @memberof WorkQueueItem
   */
  blockName?: string | null
}
/**
 *
 * @export
 * @interface WorkQueueSaveDto
 */
export interface WorkQueueSaveDto {
  /**
   *
   * @type {number}
   * @memberof WorkQueueSaveDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof WorkQueueSaveDto
   */
  bay: number
  /**
   *
   * @type {number}
   * @memberof WorkQueueSaveDto
   */
  order: number
  /**
   *
   * @type {OperationType}
   * @memberof WorkQueueSaveDto
   */
  operationType: OperationType
  /**
   *
   * @type {VesselArea}
   * @memberof WorkQueueSaveDto
   */
  vesselArea: VesselArea
  /**
   *
   * @type {number}
   * @memberof WorkQueueSaveDto
   */
  containersAmount: number
  /**
   *
   * @type {boolean}
   * @memberof WorkQueueSaveDto
   */
  isTwinContainers: boolean
  /**
   *
   * @type {number}
   * @memberof WorkQueueSaveDto
   */
  moves: number
  /**
   *
   * @type {number}
   * @memberof WorkQueueSaveDto
   */
  processingTimeSeconds: number
  /**
   *
   * @type {string}
   * @memberof WorkQueueSaveDto
   */
  startTime: string
  /**
   *
   * @type {number}
   * @memberof WorkQueueSaveDto
   */
  craneId: number
  /**
   *
   * @type {Array<number>}
   * @memberof WorkQueueSaveDto
   */
  workInstructionIds: Array<number>
}
/**
 *
 * @export
 * @interface WorkQueueSaveResponseDto
 */
export interface WorkQueueSaveResponseDto {
  /**
   *
   * @type {string}
   * @memberof WorkQueueSaveResponseDto
   */
  validationMessage?: string | null
}
/**
 *
 * @export
 * @interface YardBlockAssignmentDto
 */
export interface YardBlockAssignmentDto {
  /**
   *
   * @type {string}
   * @memberof YardBlockAssignmentDto
   */
  yardBlockId: string
  /**
   *
   * @type {Array<string>}
   * @memberof YardBlockAssignmentDto
   */
  bays: Array<string>
}
/**
 *
 * @export
 * @interface YardBlockDto
 */
export interface YardBlockDto {
  /**
   *
   * @type {string}
   * @memberof YardBlockDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof YardBlockDto
   */
  name: string
  /**
   *
   * @type {EquipmentTypes}
   * @memberof YardBlockDto
   */
  equipmentTypes: EquipmentTypes
  /**
   *
   * @type {number}
   * @memberof YardBlockDto
   */
  workAreaId?: number | null
  /**
   *
   * @type {Array<string>}
   * @memberof YardBlockDto
   */
  bays: Array<string>
}
/**
 *
 * @export
 * @interface YardBlockEquipmentAssignmentDto
 */
export interface YardBlockEquipmentAssignmentDto {
  /**
   *
   * @type {string}
   * @memberof YardBlockEquipmentAssignmentDto
   */
  yardBlockId: string
  /**
   *
   * @type {string}
   * @memberof YardBlockEquipmentAssignmentDto
   */
  yardBlockName: string
  /**
   *
   * @type {Array<string>}
   * @memberof YardBlockEquipmentAssignmentDto
   */
  bays: Array<string>
  /**
   *
   * @type {Array<WorkInstructionEquipmentDto>}
   * @memberof YardBlockEquipmentAssignmentDto
   */
  equipments: Array<WorkInstructionEquipmentDto>
}
/**
 *
 * @export
 * @interface YardBlockSlotLocation
 */
export interface YardBlockSlotLocation {
  /**
   *
   * @type {string}
   * @memberof YardBlockSlotLocation
   */
  block: string
  /**
   *
   * @type {string}
   * @memberof YardBlockSlotLocation
   */
  bay: string
  /**
   *
   * @type {string}
   * @memberof YardBlockSlotLocation
   */
  row: string
  /**
   *
   * @type {number}
   * @memberof YardBlockSlotLocation
   */
  tier: number
}

/**
 * CargoUnitsApi - axios parameter creator
 * @export
 */
export const CargoUnitsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [cargoUnitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableEmpties: async (
      cargoUnitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/CargoUnits/getAvailableEmpties`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (cargoUnitId !== undefined) {
        localVarQueryParameter['CargoUnitId'] = cargoUnitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnTerminal: async (
      query?: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/CargoUnits/getOnTerminal`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReefersOnTerminal: async (
      query?: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/CargoUnits/getReefersOnTerminal`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CargoUnitsApi - functional programming interface
 * @export
 */
export const CargoUnitsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CargoUnitsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} [cargoUnitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAvailableEmpties(
      cargoUnitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CargoUnitDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableEmpties(
        cargoUnitId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOnTerminal(
      query?: object,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CargoUnitDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOnTerminal(query, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReefersOnTerminal(
      query?: object,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CargoUnitDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReefersOnTerminal(query, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CargoUnitsApi - factory interface
 * @export
 */
export const CargoUnitsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CargoUnitsApiFp(configuration)
  return {
    /**
     *
     * @param {number} [cargoUnitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableEmpties(cargoUnitId?: number, options?: any): AxiosPromise<Array<CargoUnitDto>> {
      return localVarFp
        .getAvailableEmpties(cargoUnitId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnTerminal(query?: object, options?: any): AxiosPromise<Array<CargoUnitDto>> {
      return localVarFp.getOnTerminal(query, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReefersOnTerminal(query?: object, options?: any): AxiosPromise<Array<CargoUnitDto>> {
      return localVarFp
        .getReefersOnTerminal(query, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * CargoUnitsApi - object-oriented interface
 * @export
 * @class CargoUnitsApi
 * @extends {BaseAPI}
 */
export class CargoUnitsApi extends BaseAPI {
  /**
   *
   * @param {number} [cargoUnitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CargoUnitsApi
   */
  public getAvailableEmpties(cargoUnitId?: number, options?: AxiosRequestConfig) {
    return CargoUnitsApiFp(this.configuration)
      .getAvailableEmpties(cargoUnitId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {object} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CargoUnitsApi
   */
  public getOnTerminal(query?: object, options?: AxiosRequestConfig) {
    return CargoUnitsApiFp(this.configuration)
      .getOnTerminal(query, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {object} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CargoUnitsApi
   */
  public getReefersOnTerminal(query?: object, options?: AxiosRequestConfig) {
    return CargoUnitsApiFp(this.configuration)
      .getReefersOnTerminal(query, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * CarrierVisitsApi - axios parameter creator
 * @export
 */
export const CarrierVisitsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CarrierType} [type]
     * @param {boolean} [withFutureEtd]
     * @param {Array<CarrierVisitStatus>} [carrierVisitStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      type?: CarrierType,
      withFutureEtd?: boolean,
      carrierVisitStatuses?: Array<CarrierVisitStatus>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/CarrierVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type
      }

      if (withFutureEtd !== undefined) {
        localVarQueryParameter['WithFutureEtd'] = withFutureEtd
      }

      if (carrierVisitStatuses) {
        localVarQueryParameter['CarrierVisitStatuses'] = carrierVisitStatuses
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getOne', 'id', id)
      const localVarPath = `/operations/api/CarrierVisits/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVesselBay: async (
      carrierVisitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/CarrierVisits/GetVesselBay`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (carrierVisitId !== undefined) {
        localVarQueryParameter['carrierVisitId'] = carrierVisitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CarrierVisitsApi - functional programming interface
 * @export
 */
export const CarrierVisitsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CarrierVisitsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CarrierType} [type]
     * @param {boolean} [withFutureEtd]
     * @param {Array<CarrierVisitStatus>} [carrierVisitStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      type?: CarrierType,
      withFutureEtd?: boolean,
      carrierVisitStatuses?: Array<CarrierVisitStatus>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarrierVisitDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        type,
        withFutureEtd,
        carrierVisitStatuses,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOne(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarrierVisitDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVesselBay(
      carrierVisitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselGeometryDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselBay(
        carrierVisitId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CarrierVisitsApi - factory interface
 * @export
 */
export const CarrierVisitsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CarrierVisitsApiFp(configuration)
  return {
    /**
     *
     * @param {CarrierType} [type]
     * @param {boolean} [withFutureEtd]
     * @param {Array<CarrierVisitStatus>} [carrierVisitStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      type?: CarrierType,
      withFutureEtd?: boolean,
      carrierVisitStatuses?: Array<CarrierVisitStatus>,
      options?: any,
    ): AxiosPromise<Array<CarrierVisitDto>> {
      return localVarFp
        .get(type, withFutureEtd, carrierVisitStatuses, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOne(id: number, options?: any): AxiosPromise<CarrierVisitDto> {
      return localVarFp.getOne(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVesselBay(carrierVisitId?: number, options?: any): AxiosPromise<VesselGeometryDto> {
      return localVarFp
        .getVesselBay(carrierVisitId, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * CarrierVisitsApi - object-oriented interface
 * @export
 * @class CarrierVisitsApi
 * @extends {BaseAPI}
 */
export class CarrierVisitsApi extends BaseAPI {
  /**
   *
   * @param {CarrierType} [type]
   * @param {boolean} [withFutureEtd]
   * @param {Array<CarrierVisitStatus>} [carrierVisitStatuses]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public get(
    type?: CarrierType,
    withFutureEtd?: boolean,
    carrierVisitStatuses?: Array<CarrierVisitStatus>,
    options?: AxiosRequestConfig,
  ) {
    return CarrierVisitsApiFp(this.configuration)
      .get(type, withFutureEtd, carrierVisitStatuses, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public getOne(id: number, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .getOne(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [carrierVisitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public getVesselBay(carrierVisitId?: number, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .getVesselBay(carrierVisitId, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * ContainerStowageLocationsApi - axios parameter creator
 * @export
 */
export const ContainerStowageLocationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      carrierVisitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/ContainerStowageLocations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (carrierVisitId !== undefined) {
        localVarQueryParameter['CarrierVisitId'] = carrierVisitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ContainerStowageLocationsApi - functional programming interface
 * @export
 */
export const ContainerStowageLocationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContainerStowageLocationsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      carrierVisitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerStowageLocationDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(carrierVisitId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ContainerStowageLocationsApi - factory interface
 * @export
 */
export const ContainerStowageLocationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContainerStowageLocationsApiFp(configuration)
  return {
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(carrierVisitId?: number, options?: any): AxiosPromise<Array<ContainerStowageLocationDto>> {
      return localVarFp.get(carrierVisitId, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * ContainerStowageLocationsApi - object-oriented interface
 * @export
 * @class ContainerStowageLocationsApi
 * @extends {BaseAPI}
 */
export class ContainerStowageLocationsApi extends BaseAPI {
  /**
   *
   * @param {number} [carrierVisitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerStowageLocationsApi
   */
  public get(carrierVisitId?: number, options?: AxiosRequestConfig) {
    return ContainerStowageLocationsApiFp(this.configuration)
      .get(carrierVisitId, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * DemoGcsApi - axios parameter creator
 * @export
 */
export const DemoGcsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (path?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/DemoGcs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (path !== undefined) {
        localVarQueryParameter['path'] = path
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    download: async (path?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/DemoGcs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (path !== undefined) {
        localVarQueryParameter['path'] = path
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [path]
     * @param {number} [validMinutes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignedUrl: async (
      path?: string,
      validMinutes?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/DemoGcs/GetSignedUrlAsync`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (path !== undefined) {
        localVarQueryParameter['path'] = path
      }

      if (validMinutes !== undefined) {
        localVarQueryParameter['validMinutes'] = validMinutes
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAllFileNames: async (
      path?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/DemoGcs/ListAllFileNamesAsync`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (path !== undefined) {
        localVarQueryParameter['path'] = path
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [folderName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upload: async (
      folderName?: string,
      file?: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/DemoGcs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)()

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (folderName !== undefined) {
        localVarQueryParameter['folderName'] = folderName
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DemoGcsApi - functional programming interface
 * @export
 */
export const DemoGcsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DemoGcsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      path?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(path, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async download(
      path?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.download(path, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [path]
     * @param {number} [validMinutes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSignedUrl(
      path?: string,
      validMinutes?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSignedUrl(
        path,
        validMinutes,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listAllFileNames(
      path?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listAllFileNames(path, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [folderName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upload(
      folderName?: string,
      file?: File,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upload(folderName, file, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * DemoGcsApi - factory interface
 * @export
 */
export const DemoGcsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DemoGcsApiFp(configuration)
  return {
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(path?: string, options?: any): AxiosPromise<void> {
      return localVarFp._delete(path, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    download(path?: string, options?: any): AxiosPromise<void> {
      return localVarFp.download(path, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [path]
     * @param {number} [validMinutes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignedUrl(path?: string, validMinutes?: number, options?: any): AxiosPromise<string> {
      return localVarFp
        .getSignedUrl(path, validMinutes, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAllFileNames(path?: string, options?: any): AxiosPromise<Array<string>> {
      return localVarFp.listAllFileNames(path, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [folderName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upload(folderName?: string, file?: File, options?: any): AxiosPromise<string> {
      return localVarFp.upload(folderName, file, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * DemoGcsApi - object-oriented interface
 * @export
 * @class DemoGcsApi
 * @extends {BaseAPI}
 */
export class DemoGcsApi extends BaseAPI {
  /**
   *
   * @param {string} [path]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DemoGcsApi
   */
  public _delete(path?: string, options?: AxiosRequestConfig) {
    return DemoGcsApiFp(this.configuration)
      ._delete(path, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [path]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DemoGcsApi
   */
  public download(path?: string, options?: AxiosRequestConfig) {
    return DemoGcsApiFp(this.configuration)
      .download(path, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [path]
   * @param {number} [validMinutes]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DemoGcsApi
   */
  public getSignedUrl(path?: string, validMinutes?: number, options?: AxiosRequestConfig) {
    return DemoGcsApiFp(this.configuration)
      .getSignedUrl(path, validMinutes, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [path]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DemoGcsApi
   */
  public listAllFileNames(path?: string, options?: AxiosRequestConfig) {
    return DemoGcsApiFp(this.configuration)
      .listAllFileNames(path, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [folderName]
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DemoGcsApi
   */
  public upload(folderName?: string, file?: File, options?: AxiosRequestConfig) {
    return DemoGcsApiFp(this.configuration)
      .upload(folderName, file, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * EquipmentsApi - axios parameter creator
 * @export
 */
export const EquipmentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/operations/api/Equipments/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {AssignRequest} [assignRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assign: async (
      assignRequest?: AssignRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Equipments/assign`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        assignRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {AssignToJobByTallymanRequest} [assignToJobByTallymanRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignToJobByTallyman: async (
      assignToJobByTallymanRequest?: AssignToJobByTallymanRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Equipments/assignToJobByTallyman`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        assignToJobByTallymanRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [currentDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    craneQueueStats: async (
      currentDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Equipments/getCraneQueueStats`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (currentDate !== undefined) {
        localVarQueryParameter['CurrentDate'] =
          (currentDate as any) instanceof Date ? (currentDate as any).toISOString() : currentDate
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateRequest} [createRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createRequest?: CreateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Equipments`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [currentDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    equipmentStats: async (
      currentDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Equipments/getEquipmentStats`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (currentDate !== undefined) {
        localVarQueryParameter['CurrentDate'] =
          (currentDate as any) instanceof Date ? (currentDate as any).toISOString() : currentDate
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [vesselVisitId]
     * @param {EquipmentType} [equipmentType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      vesselVisitId?: number,
      equipmentType?: EquipmentType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Equipments`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (vesselVisitId !== undefined) {
        localVarQueryParameter['VesselVisitId'] = vesselVisitId
      }

      if (equipmentType !== undefined) {
        localVarQueryParameter['EquipmentType'] = equipmentType
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {number} [orderId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getForTallymanOfDischarging: async (
      carrierVisitId?: number,
      orderId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Equipments/getForTallymanOfDischarging`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (carrierVisitId !== undefined) {
        localVarQueryParameter['CarrierVisitId'] = carrierVisitId
      }

      if (orderId !== undefined) {
        localVarQueryParameter['OrderId'] = orderId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [visitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdsAlreadyInUseByOtherVisits: async (
      visitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Equipments/getIdsAlreadyInUseByOtherVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (visitId !== undefined) {
        localVarQueryParameter['VisitId'] = visitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getOne', 'id', id)
      const localVarPath = `/operations/api/Equipments/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanned: async (
      carrierVisitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Equipments/getPlannedByVesselVisit`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (carrierVisitId !== undefined) {
        localVarQueryParameter['CarrierVisitId'] = carrierVisitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UnassignRequest} [unassignRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unassign: async (
      unassignRequest?: UnassignRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Equipments/unassign`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        unassignRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateRequest} [updateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      updateRequest?: UpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Equipments`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * EquipmentsApi - functional programming interface
 * @export
 */
export const EquipmentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EquipmentsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {AssignRequest} [assignRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assign(
      assignRequest?: AssignRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assign(assignRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {AssignToJobByTallymanRequest} [assignToJobByTallymanRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignToJobByTallyman(
      assignToJobByTallymanRequest?: AssignToJobByTallymanRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assignToJobByTallyman(
        assignToJobByTallymanRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [currentDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async craneQueueStats(
      currentDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CraneQueueDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.craneQueueStats(
        currentDate,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateRequest} [createRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createRequest?: CreateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(createRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [currentDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async equipmentStats(
      currentDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentStatsDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentStats(currentDate, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [vesselVisitId]
     * @param {EquipmentType} [equipmentType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      vesselVisitId?: number,
      equipmentType?: EquipmentType,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        vesselVisitId,
        equipmentType,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {number} [orderId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getForTallymanOfDischarging(
      carrierVisitId?: number,
      orderId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getForTallymanOfDischarging(
        carrierVisitId,
        orderId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [visitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdsAlreadyInUseByOtherVisits(
      visitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdsAlreadyInUseByOtherVisits(
        visitId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOne(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlanned(
      carrierVisitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentPlannedDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPlanned(carrierVisitId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UnassignRequest} [unassignRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unassign(
      unassignRequest?: UnassignRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unassign(unassignRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateRequest} [updateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      updateRequest?: UpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(updateRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * EquipmentsApi - factory interface
 * @export
 */
export const EquipmentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EquipmentsApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp._delete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {AssignRequest} [assignRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assign(assignRequest?: AssignRequest, options?: any): AxiosPromise<void> {
      return localVarFp.assign(assignRequest, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {AssignToJobByTallymanRequest} [assignToJobByTallymanRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignToJobByTallyman(
      assignToJobByTallymanRequest?: AssignToJobByTallymanRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .assignToJobByTallyman(assignToJobByTallymanRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [currentDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    craneQueueStats(currentDate?: string, options?: any): AxiosPromise<Array<CraneQueueDto>> {
      return localVarFp
        .craneQueueStats(currentDate, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateRequest} [createRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(createRequest?: CreateRequest, options?: any): AxiosPromise<number> {
      return localVarFp.create(createRequest, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [currentDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    equipmentStats(currentDate?: string, options?: any): AxiosPromise<Array<EquipmentStatsDto>> {
      return localVarFp
        .equipmentStats(currentDate, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [vesselVisitId]
     * @param {EquipmentType} [equipmentType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      vesselVisitId?: number,
      equipmentType?: EquipmentType,
      options?: any,
    ): AxiosPromise<Array<EquipmentDto>> {
      return localVarFp
        .get(vesselVisitId, equipmentType, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {number} [orderId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getForTallymanOfDischarging(
      carrierVisitId?: number,
      orderId?: number,
      options?: any,
    ): AxiosPromise<Array<EquipmentDto>> {
      return localVarFp
        .getForTallymanOfDischarging(carrierVisitId, orderId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [visitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdsAlreadyInUseByOtherVisits(visitId?: number, options?: any): AxiosPromise<Array<number>> {
      return localVarFp
        .getIdsAlreadyInUseByOtherVisits(visitId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOne(id: number, options?: any): AxiosPromise<EquipmentDto> {
      return localVarFp.getOne(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanned(carrierVisitId?: number, options?: any): AxiosPromise<Array<EquipmentPlannedDto>> {
      return localVarFp
        .getPlanned(carrierVisitId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UnassignRequest} [unassignRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unassign(unassignRequest?: UnassignRequest, options?: any): AxiosPromise<void> {
      return localVarFp.unassign(unassignRequest, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateRequest} [updateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(updateRequest?: UpdateRequest, options?: any): AxiosPromise<number> {
      return localVarFp.update(updateRequest, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * EquipmentsApi - object-oriented interface
 * @export
 * @class EquipmentsApi
 * @extends {BaseAPI}
 */
export class EquipmentsApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public _delete(id: number, options?: AxiosRequestConfig) {
    return EquipmentsApiFp(this.configuration)
      ._delete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {AssignRequest} [assignRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public assign(assignRequest?: AssignRequest, options?: AxiosRequestConfig) {
    return EquipmentsApiFp(this.configuration)
      .assign(assignRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {AssignToJobByTallymanRequest} [assignToJobByTallymanRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public assignToJobByTallyman(
    assignToJobByTallymanRequest?: AssignToJobByTallymanRequest,
    options?: AxiosRequestConfig,
  ) {
    return EquipmentsApiFp(this.configuration)
      .assignToJobByTallyman(assignToJobByTallymanRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [currentDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public craneQueueStats(currentDate?: string, options?: AxiosRequestConfig) {
    return EquipmentsApiFp(this.configuration)
      .craneQueueStats(currentDate, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateRequest} [createRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public create(createRequest?: CreateRequest, options?: AxiosRequestConfig) {
    return EquipmentsApiFp(this.configuration)
      .create(createRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [currentDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public equipmentStats(currentDate?: string, options?: AxiosRequestConfig) {
    return EquipmentsApiFp(this.configuration)
      .equipmentStats(currentDate, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [vesselVisitId]
   * @param {EquipmentType} [equipmentType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public get(vesselVisitId?: number, equipmentType?: EquipmentType, options?: AxiosRequestConfig) {
    return EquipmentsApiFp(this.configuration)
      .get(vesselVisitId, equipmentType, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [carrierVisitId]
   * @param {number} [orderId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public getForTallymanOfDischarging(
    carrierVisitId?: number,
    orderId?: number,
    options?: AxiosRequestConfig,
  ) {
    return EquipmentsApiFp(this.configuration)
      .getForTallymanOfDischarging(carrierVisitId, orderId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [visitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public getIdsAlreadyInUseByOtherVisits(visitId?: number, options?: AxiosRequestConfig) {
    return EquipmentsApiFp(this.configuration)
      .getIdsAlreadyInUseByOtherVisits(visitId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public getOne(id: number, options?: AxiosRequestConfig) {
    return EquipmentsApiFp(this.configuration)
      .getOne(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [carrierVisitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public getPlanned(carrierVisitId?: number, options?: AxiosRequestConfig) {
    return EquipmentsApiFp(this.configuration)
      .getPlanned(carrierVisitId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UnassignRequest} [unassignRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public unassign(unassignRequest?: UnassignRequest, options?: AxiosRequestConfig) {
    return EquipmentsApiFp(this.configuration)
      .unassign(unassignRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateRequest} [updateRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EquipmentsApi
   */
  public update(updateRequest?: UpdateRequest, options?: AxiosRequestConfig) {
    return EquipmentsApiFp(this.configuration)
      .update(updateRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * IssuesApi - axios parameter creator
 * @export
 */
export const IssuesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Issues`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * IssuesApi - functional programming interface
 * @export
 */
export const IssuesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = IssuesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAll(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IssueDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * IssuesApi - factory interface
 * @export
 */
export const IssuesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = IssuesApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(options?: any): AxiosPromise<Array<IssueDto>> {
      return localVarFp.getAll(options).then(request => request(axios, basePath))
    },
  }
}

/**
 * IssuesApi - object-oriented interface
 * @export
 * @class IssuesApi
 * @extends {BaseAPI}
 */
export class IssuesApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IssuesApi
   */
  public getAll(options?: AxiosRequestConfig) {
    return IssuesApiFp(this.configuration)
      .getAll(options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CancelStartJobRequest} [cancelStartJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelStartJob: async (
      cancelStartJobRequest?: CancelStartJobRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Jobs/cancelStart`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        cancelStartJobRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {FinishJobRequest} [finishJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishJob: async (
      finishJobRequest?: FinishJobRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Jobs/finish`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        finishJobRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [equipmentId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNextByEquipment: async (
      equipmentId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Jobs/getNextByEquipment`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (equipmentId !== undefined) {
        localVarQueryParameter['EquipmentId'] = equipmentId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {StartJobRequest} [startJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startJob: async (
      startJobRequest?: StartJobRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Jobs/start`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        startJobRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UploadSignaturesRequest} [uploadSignaturesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadSignatures: async (
      uploadSignaturesRequest?: UploadSignaturesRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/Jobs/signature`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        uploadSignaturesRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CancelStartJobRequest} [cancelStartJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelStartJob(
      cancelStartJobRequest?: CancelStartJobRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelStartJob(
        cancelStartJobRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {FinishJobRequest} [finishJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async finishJob(
      finishJobRequest?: FinishJobRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.finishJob(finishJobRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [equipmentId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNextByEquipment(
      equipmentId?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobAndYardBlockEquipmentAssignmentDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNextByEquipment(
        equipmentId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {StartJobRequest} [startJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startJob(
      startJobRequest?: StartJobRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startJob(startJobRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UploadSignaturesRequest} [uploadSignaturesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadSignatures(
      uploadSignaturesRequest?: UploadSignaturesRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadSignatures(
        uploadSignaturesRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = JobsApiFp(configuration)
  return {
    /**
     *
     * @param {CancelStartJobRequest} [cancelStartJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelStartJob(
      cancelStartJobRequest?: CancelStartJobRequest,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .cancelStartJob(cancelStartJobRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {FinishJobRequest} [finishJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishJob(finishJobRequest?: FinishJobRequest, options?: any): AxiosPromise<number> {
      return localVarFp
        .finishJob(finishJobRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [equipmentId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNextByEquipment(
      equipmentId?: number,
      options?: any,
    ): AxiosPromise<JobAndYardBlockEquipmentAssignmentDto> {
      return localVarFp
        .getNextByEquipment(equipmentId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {StartJobRequest} [startJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startJob(startJobRequest?: StartJobRequest, options?: any): AxiosPromise<number> {
      return localVarFp.startJob(startJobRequest, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UploadSignaturesRequest} [uploadSignaturesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadSignatures(
      uploadSignaturesRequest?: UploadSignaturesRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .uploadSignatures(uploadSignaturesRequest, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
  /**
   *
   * @param {CancelStartJobRequest} [cancelStartJobRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public cancelStartJob(
    cancelStartJobRequest?: CancelStartJobRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .cancelStartJob(cancelStartJobRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {FinishJobRequest} [finishJobRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public finishJob(finishJobRequest?: FinishJobRequest, options?: AxiosRequestConfig) {
    return JobsApiFp(this.configuration)
      .finishJob(finishJobRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [equipmentId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getNextByEquipment(equipmentId?: number, options?: AxiosRequestConfig) {
    return JobsApiFp(this.configuration)
      .getNextByEquipment(equipmentId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {StartJobRequest} [startJobRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public startJob(startJobRequest?: StartJobRequest, options?: AxiosRequestConfig) {
    return JobsApiFp(this.configuration)
      .startJob(startJobRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UploadSignaturesRequest} [uploadSignaturesRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public uploadSignatures(
    uploadSignaturesRequest?: UploadSignaturesRequest,
    options?: AxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .uploadSignatures(uploadSignaturesRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * ReeferTemperaturesApi - axios parameter creator
 * @export
 */
export const ReeferTemperaturesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateRequest1} [createRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createRequest1?: CreateRequest1,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/ReeferTemperatures`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRequest1,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [containerNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCoolingOrdersHistoryByContainer: async (
      containerNumber?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/ReeferTemperatures/getCoolingOrdersHistoryByContainer`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (containerNumber !== undefined) {
        localVarQueryParameter['ContainerNumber'] = containerNumber
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [containerNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCoolingOrdersHistoryByContainerV2: async (
      containerNumber?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/ReeferTemperatures/getCoolingOrdersHistoryByContainerV2`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (containerNumber !== undefined) {
        localVarQueryParameter['ContainerNumber'] = containerNumber
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {boolean} [isForOperator]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInProgressCoolingOrders: async (
      isForOperator?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/ReeferTemperatures/getInProgressCoolingOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (isForOperator !== undefined) {
        localVarQueryParameter['IsForOperator'] = isForOperator
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {boolean} [isForOperator]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInProgressCoolingOrdersV2: async (
      isForOperator?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/ReeferTemperatures/getInProgressCoolingOrdersV2`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (isForOperator !== undefined) {
        localVarQueryParameter['IsForOperator'] = isForOperator
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/ReeferTemperatures/syncCustomers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateRequest1} [updateRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      updateRequest1?: UpdateRequest1,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/ReeferTemperatures`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateRequest1,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ReeferTemperaturesApi - functional programming interface
 * @export
 */
export const ReeferTemperaturesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReeferTemperaturesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CreateRequest1} [createRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createRequest1?: CreateRequest1,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(createRequest1, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [containerNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCoolingOrdersHistoryByContainer(
      containerNumber?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoolingOrderDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCoolingOrdersHistoryByContainer(
        containerNumber,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [containerNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCoolingOrdersHistoryByContainerV2(
      containerNumber?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoolingOrderDtoV2>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCoolingOrdersHistoryByContainerV2(
          containerNumber,
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {boolean} [isForOperator]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInProgressCoolingOrders(
      isForOperator?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoolingOrderDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInProgressCoolingOrders(
        isForOperator,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {boolean} [isForOperator]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInProgressCoolingOrdersV2(
      isForOperator?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoolingOrderDtoV2>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInProgressCoolingOrdersV2(
        isForOperator,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateRequest1} [updateRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      updateRequest1?: UpdateRequest1,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(updateRequest1, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ReeferTemperaturesApi - factory interface
 * @export
 */
export const ReeferTemperaturesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ReeferTemperaturesApiFp(configuration)
  return {
    /**
     *
     * @param {CreateRequest1} [createRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(createRequest1?: CreateRequest1, options?: any): AxiosPromise<number> {
      return localVarFp.create(createRequest1, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [containerNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCoolingOrdersHistoryByContainer(
      containerNumber?: string,
      options?: any,
    ): AxiosPromise<Array<CoolingOrderDto>> {
      return localVarFp
        .getCoolingOrdersHistoryByContainer(containerNumber, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [containerNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCoolingOrdersHistoryByContainerV2(
      containerNumber?: string,
      options?: any,
    ): AxiosPromise<Array<CoolingOrderDtoV2>> {
      return localVarFp
        .getCoolingOrdersHistoryByContainerV2(containerNumber, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {boolean} [isForOperator]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInProgressCoolingOrders(
      isForOperator?: boolean,
      options?: any,
    ): AxiosPromise<Array<CoolingOrderDto>> {
      return localVarFp
        .getInProgressCoolingOrders(isForOperator, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {boolean} [isForOperator]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInProgressCoolingOrdersV2(
      isForOperator?: boolean,
      options?: any,
    ): AxiosPromise<Array<CoolingOrderDtoV2>> {
      return localVarFp
        .getInProgressCoolingOrdersV2(isForOperator, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.post(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateRequest1} [updateRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(updateRequest1?: UpdateRequest1, options?: any): AxiosPromise<void> {
      return localVarFp.update(updateRequest1, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * ReeferTemperaturesApi - object-oriented interface
 * @export
 * @class ReeferTemperaturesApi
 * @extends {BaseAPI}
 */
export class ReeferTemperaturesApi extends BaseAPI {
  /**
   *
   * @param {CreateRequest1} [createRequest1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReeferTemperaturesApi
   */
  public create(createRequest1?: CreateRequest1, options?: AxiosRequestConfig) {
    return ReeferTemperaturesApiFp(this.configuration)
      .create(createRequest1, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [containerNumber]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReeferTemperaturesApi
   */
  public getCoolingOrdersHistoryByContainer(
    containerNumber?: string,
    options?: AxiosRequestConfig,
  ) {
    return ReeferTemperaturesApiFp(this.configuration)
      .getCoolingOrdersHistoryByContainer(containerNumber, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [containerNumber]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReeferTemperaturesApi
   */
  public getCoolingOrdersHistoryByContainerV2(
    containerNumber?: string,
    options?: AxiosRequestConfig,
  ) {
    return ReeferTemperaturesApiFp(this.configuration)
      .getCoolingOrdersHistoryByContainerV2(containerNumber, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {boolean} [isForOperator]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReeferTemperaturesApi
   */
  public getInProgressCoolingOrders(isForOperator?: boolean, options?: AxiosRequestConfig) {
    return ReeferTemperaturesApiFp(this.configuration)
      .getInProgressCoolingOrders(isForOperator, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {boolean} [isForOperator]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReeferTemperaturesApi
   */
  public getInProgressCoolingOrdersV2(isForOperator?: boolean, options?: AxiosRequestConfig) {
    return ReeferTemperaturesApiFp(this.configuration)
      .getInProgressCoolingOrdersV2(isForOperator, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReeferTemperaturesApi
   */
  public post(from?: string, options?: AxiosRequestConfig) {
    return ReeferTemperaturesApiFp(this.configuration)
      .post(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateRequest1} [updateRequest1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReeferTemperaturesApi
   */
  public update(updateRequest1?: UpdateRequest1, options?: AxiosRequestConfig) {
    return ReeferTemperaturesApiFp(this.configuration)
      .update(updateRequest1, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * ResetDemoDataFromTenantApi - axios parameter creator
 * @export
 */
export const ResetDemoDataFromTenantApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetDataFromTenant: async (
      body?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/ResetDemoDataFromTenant/ResetDataFromTenant`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ResetDemoDataFromTenantApi - functional programming interface
 * @export
 */
export const ResetDemoDataFromTenantApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ResetDemoDataFromTenantApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetDataFromTenant(
      body?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetDataFromTenant(body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ResetDemoDataFromTenantApi - factory interface
 * @export
 */
export const ResetDemoDataFromTenantApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ResetDemoDataFromTenantApiFp(configuration)
  return {
    /**
     *
     * @param {number} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetDataFromTenant(body?: number, options?: any): AxiosPromise<void> {
      return localVarFp.resetDataFromTenant(body, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * ResetDemoDataFromTenantApi - object-oriented interface
 * @export
 * @class ResetDemoDataFromTenantApi
 * @extends {BaseAPI}
 */
export class ResetDemoDataFromTenantApi extends BaseAPI {
  /**
   *
   * @param {number} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResetDemoDataFromTenantApi
   */
  public resetDataFromTenant(body?: number, options?: AxiosRequestConfig) {
    return ResetDemoDataFromTenantApiFp(this.configuration)
      .resetDataFromTenant(body, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * TenantConfigsApi - axios parameter creator
 * @export
 */
export const TenantConfigsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/TenantConfigs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpsertRequest} [upsertRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsert: async (
      upsertRequest?: UpsertRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/TenantConfigs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        upsertRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TenantConfigsApi - functional programming interface
 * @export
 */
export const TenantConfigsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TenantConfigsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAll(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConfigDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpsertRequest} [upsertRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsert(
      upsertRequest?: UpsertRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsert(upsertRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TenantConfigsApi - factory interface
 * @export
 */
export const TenantConfigsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TenantConfigsApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(options?: any): AxiosPromise<TenantConfigDto> {
      return localVarFp.getAll(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpsertRequest} [upsertRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsert(upsertRequest?: UpsertRequest, options?: any): AxiosPromise<void> {
      return localVarFp.upsert(upsertRequest, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * TenantConfigsApi - object-oriented interface
 * @export
 * @class TenantConfigsApi
 * @extends {BaseAPI}
 */
export class TenantConfigsApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantConfigsApi
   */
  public getAll(options?: AxiosRequestConfig) {
    return TenantConfigsApiFp(this.configuration)
      .getAll(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpsertRequest} [upsertRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantConfigsApi
   */
  public upsert(upsertRequest?: UpsertRequest, options?: AxiosRequestConfig) {
    return TenantConfigsApiFp(this.configuration)
      .upsert(upsertRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * WorkAreasApi - axios parameter creator
 * @export
 */
export const WorkAreasApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      carrierVisitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/WorkAreas`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (carrierVisitId !== undefined) {
        localVarQueryParameter['CarrierVisitId'] = carrierVisitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WorkAreasApi - functional programming interface
 * @export
 */
export const WorkAreasApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WorkAreasApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      carrierVisitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkAreaDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(carrierVisitId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * WorkAreasApi - factory interface
 * @export
 */
export const WorkAreasApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WorkAreasApiFp(configuration)
  return {
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(carrierVisitId?: number, options?: any): AxiosPromise<Array<WorkAreaDto>> {
      return localVarFp.get(carrierVisitId, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * WorkAreasApi - object-oriented interface
 * @export
 * @class WorkAreasApi
 * @extends {BaseAPI}
 */
export class WorkAreasApi extends BaseAPI {
  /**
   *
   * @param {number} [carrierVisitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkAreasApi
   */
  public get(carrierVisitId?: number, options?: AxiosRequestConfig) {
    return WorkAreasApiFp(this.configuration)
      .get(carrierVisitId, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * WorkInstructionsApi - axios parameter creator
 * @export
 */
export const WorkInstructionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateRequest2} [createRequest2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createRequest2?: CreateRequest2,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/WorkInstructions/createServiceWorkInstruction`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRequest2,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateInternalWorkInstructionRequest} [createInternalWorkInstructionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInternalWorkInstruction: async (
      createInternalWorkInstructionRequest?: CreateInternalWorkInstructionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/WorkInstructions/createInternalWorkInstruction`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createInternalWorkInstructionRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {FinishWorkInstructionJobRequest} [finishWorkInstructionJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishWorkInstructionJob: async (
      finishWorkInstructionJobRequest?: FinishWorkInstructionJobRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/WorkInstructions/finishWorkInstructionJob`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        finishWorkInstructionJobRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {number} [equipmentId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      carrierVisitId?: number,
      equipmentId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/WorkInstructions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (carrierVisitId !== undefined) {
        localVarQueryParameter['CarrierVisitId'] = carrierVisitId
      }

      if (equipmentId !== undefined) {
        localVarQueryParameter['EquipmentId'] = equipmentId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkInsturctionsAsJobs: async (
      query?: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/WorkInstructions/getWorkInsturctionsAsJobs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [railVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railcarTrackPositionsByRailVisitId: async (
      railVisitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/WorkInstructions/RailcarTrackPositionsByRailVisitId`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (railVisitId !== undefined) {
        localVarQueryParameter['RailVisitId'] = railVisitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WorkInstructionsApi - functional programming interface
 * @export
 */
export const WorkInstructionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WorkInstructionsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CreateRequest2} [createRequest2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createRequest2?: CreateRequest2,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(createRequest2, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateInternalWorkInstructionRequest} [createInternalWorkInstructionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInternalWorkInstruction(
      createInternalWorkInstructionRequest?: CreateInternalWorkInstructionRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createInternalWorkInstruction(
        createInternalWorkInstructionRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {FinishWorkInstructionJobRequest} [finishWorkInstructionJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async finishWorkInstructionJob(
      finishWorkInstructionJobRequest?: FinishWorkInstructionJobRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.finishWorkInstructionJob(
        finishWorkInstructionJobRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {number} [equipmentId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      carrierVisitId?: number,
      equipmentId?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkInstructionDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        carrierVisitId,
        equipmentId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkInsturctionsAsJobs(
      query?: object,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkInstructionJobDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkInsturctionsAsJobs(
        query,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [railVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async railcarTrackPositionsByRailVisitId(
      railVisitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RailcarTrackPositionDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.railcarTrackPositionsByRailVisitId(
        railVisitId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * WorkInstructionsApi - factory interface
 * @export
 */
export const WorkInstructionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WorkInstructionsApiFp(configuration)
  return {
    /**
     *
     * @param {CreateRequest2} [createRequest2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(createRequest2?: CreateRequest2, options?: any): AxiosPromise<number> {
      return localVarFp.create(createRequest2, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateInternalWorkInstructionRequest} [createInternalWorkInstructionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInternalWorkInstruction(
      createInternalWorkInstructionRequest?: CreateInternalWorkInstructionRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createInternalWorkInstruction(createInternalWorkInstructionRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {FinishWorkInstructionJobRequest} [finishWorkInstructionJobRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishWorkInstructionJob(
      finishWorkInstructionJobRequest?: FinishWorkInstructionJobRequest,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .finishWorkInstructionJob(finishWorkInstructionJobRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {number} [equipmentId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      carrierVisitId?: number,
      equipmentId?: number,
      options?: any,
    ): AxiosPromise<Array<WorkInstructionDto>> {
      return localVarFp
        .get(carrierVisitId, equipmentId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkInsturctionsAsJobs(
      query?: object,
      options?: any,
    ): AxiosPromise<Array<WorkInstructionJobDto>> {
      return localVarFp
        .getWorkInsturctionsAsJobs(query, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [railVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railcarTrackPositionsByRailVisitId(
      railVisitId?: number,
      options?: any,
    ): AxiosPromise<Array<RailcarTrackPositionDto>> {
      return localVarFp
        .railcarTrackPositionsByRailVisitId(railVisitId, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * WorkInstructionsApi - object-oriented interface
 * @export
 * @class WorkInstructionsApi
 * @extends {BaseAPI}
 */
export class WorkInstructionsApi extends BaseAPI {
  /**
   *
   * @param {CreateRequest2} [createRequest2]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkInstructionsApi
   */
  public create(createRequest2?: CreateRequest2, options?: AxiosRequestConfig) {
    return WorkInstructionsApiFp(this.configuration)
      .create(createRequest2, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateInternalWorkInstructionRequest} [createInternalWorkInstructionRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkInstructionsApi
   */
  public createInternalWorkInstruction(
    createInternalWorkInstructionRequest?: CreateInternalWorkInstructionRequest,
    options?: AxiosRequestConfig,
  ) {
    return WorkInstructionsApiFp(this.configuration)
      .createInternalWorkInstruction(createInternalWorkInstructionRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {FinishWorkInstructionJobRequest} [finishWorkInstructionJobRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkInstructionsApi
   */
  public finishWorkInstructionJob(
    finishWorkInstructionJobRequest?: FinishWorkInstructionJobRequest,
    options?: AxiosRequestConfig,
  ) {
    return WorkInstructionsApiFp(this.configuration)
      .finishWorkInstructionJob(finishWorkInstructionJobRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [carrierVisitId]
   * @param {number} [equipmentId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkInstructionsApi
   */
  public get(carrierVisitId?: number, equipmentId?: number, options?: AxiosRequestConfig) {
    return WorkInstructionsApiFp(this.configuration)
      .get(carrierVisitId, equipmentId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {object} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkInstructionsApi
   */
  public getWorkInsturctionsAsJobs(query?: object, options?: AxiosRequestConfig) {
    return WorkInstructionsApiFp(this.configuration)
      .getWorkInsturctionsAsJobs(query, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [railVisitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkInstructionsApi
   */
  public railcarTrackPositionsByRailVisitId(railVisitId?: number, options?: AxiosRequestConfig) {
    return WorkInstructionsApiFp(this.configuration)
      .railcarTrackPositionsByRailVisitId(railVisitId, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * WorkQueueApi - axios parameter creator
 * @export
 */
export const WorkQueueApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AutoGenerateWorkQueuesRequest} [autoGenerateWorkQueuesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autoGenerateWorkQueues: async (
      autoGenerateWorkQueuesRequest?: AutoGenerateWorkQueuesRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/WorkQueue/autoGenerateWorkQueues`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        autoGenerateWorkQueuesRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      carrierVisitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/WorkQueue`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (carrierVisitId !== undefined) {
        localVarQueryParameter['CarrierVisitId'] = carrierVisitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {SaveRequest} [saveRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    save: async (
      saveRequest?: SaveRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/WorkQueue/saveCraneSplit`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WorkQueueApi - functional programming interface
 * @export
 */
export const WorkQueueApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WorkQueueApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {AutoGenerateWorkQueuesRequest} [autoGenerateWorkQueuesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async autoGenerateWorkQueues(
      autoGenerateWorkQueuesRequest?: AutoGenerateWorkQueuesRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.autoGenerateWorkQueues(
        autoGenerateWorkQueuesRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      carrierVisitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkQueueDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(carrierVisitId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {SaveRequest} [saveRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async save(
      saveRequest?: SaveRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkQueueSaveResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.save(saveRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * WorkQueueApi - factory interface
 * @export
 */
export const WorkQueueApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WorkQueueApiFp(configuration)
  return {
    /**
     *
     * @param {AutoGenerateWorkQueuesRequest} [autoGenerateWorkQueuesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autoGenerateWorkQueues(
      autoGenerateWorkQueuesRequest?: AutoGenerateWorkQueuesRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .autoGenerateWorkQueues(autoGenerateWorkQueuesRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(carrierVisitId?: number, options?: any): AxiosPromise<Array<WorkQueueDto>> {
      return localVarFp.get(carrierVisitId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {SaveRequest} [saveRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    save(saveRequest?: SaveRequest, options?: any): AxiosPromise<WorkQueueSaveResponseDto> {
      return localVarFp.save(saveRequest, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * WorkQueueApi - object-oriented interface
 * @export
 * @class WorkQueueApi
 * @extends {BaseAPI}
 */
export class WorkQueueApi extends BaseAPI {
  /**
   *
   * @param {AutoGenerateWorkQueuesRequest} [autoGenerateWorkQueuesRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkQueueApi
   */
  public autoGenerateWorkQueues(
    autoGenerateWorkQueuesRequest?: AutoGenerateWorkQueuesRequest,
    options?: AxiosRequestConfig,
  ) {
    return WorkQueueApiFp(this.configuration)
      .autoGenerateWorkQueues(autoGenerateWorkQueuesRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [carrierVisitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkQueueApi
   */
  public get(carrierVisitId?: number, options?: AxiosRequestConfig) {
    return WorkQueueApiFp(this.configuration)
      .get(carrierVisitId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {SaveRequest} [saveRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkQueueApi
   */
  public save(saveRequest?: SaveRequest, options?: AxiosRequestConfig) {
    return WorkQueueApiFp(this.configuration)
      .save(saveRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * YardBlocksApi - axios parameter creator
 * @export
 */
export const YardBlocksApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {boolean} [withNoWorkAreaAssigned]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getYardBlockByWorkInstruction: async (
      withNoWorkAreaAssigned?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/YardBlocks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (withNoWorkAreaAssigned !== undefined) {
        localVarQueryParameter['WithNoWorkAreaAssigned'] = withNoWorkAreaAssigned
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncYardBlockSlots: async (
      body?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operations/api/YardBlocks/syncYardBlockSlots`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * YardBlocksApi - functional programming interface
 * @export
 */
export const YardBlocksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = YardBlocksApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {boolean} [withNoWorkAreaAssigned]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getYardBlockByWorkInstruction(
      withNoWorkAreaAssigned?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YardBlockDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getYardBlockByWorkInstruction(
        withNoWorkAreaAssigned,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncYardBlockSlots(
      body?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncYardBlockSlots(body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * YardBlocksApi - factory interface
 * @export
 */
export const YardBlocksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = YardBlocksApiFp(configuration)
  return {
    /**
     *
     * @param {boolean} [withNoWorkAreaAssigned]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getYardBlockByWorkInstruction(
      withNoWorkAreaAssigned?: boolean,
      options?: any,
    ): AxiosPromise<Array<YardBlockDto>> {
      return localVarFp
        .getYardBlockByWorkInstruction(withNoWorkAreaAssigned, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncYardBlockSlots(body?: string, options?: any): AxiosPromise<void> {
      return localVarFp.syncYardBlockSlots(body, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * YardBlocksApi - object-oriented interface
 * @export
 * @class YardBlocksApi
 * @extends {BaseAPI}
 */
export class YardBlocksApi extends BaseAPI {
  /**
   *
   * @param {boolean} [withNoWorkAreaAssigned]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof YardBlocksApi
   */
  public getYardBlockByWorkInstruction(
    withNoWorkAreaAssigned?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return YardBlocksApiFp(this.configuration)
      .getYardBlockByWorkInstruction(withNoWorkAreaAssigned, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof YardBlocksApi
   */
  public syncYardBlockSlots(body?: string, options?: AxiosRequestConfig) {
    return YardBlocksApiFp(this.configuration)
      .syncYardBlockSlots(body, options)
      .then(request => request(this.axios, this.basePath))
  }
}
