import { action, makeObservable, observable } from 'mobx'

export class ContainerYardOperationViewStore {
  containerNumber?: string
  orderId?: number | null

  hasSelectedDestination = false

  isOpen = false
  isLoading = false

  constructor() {
    makeObservable(this, {
      containerNumber: observable,
      orderId: observable,
      hasSelectedDestination: observable,
      isOpen: observable,
      isLoading: observable,

      open: action,
      close: action,
    })
  }

  setLoading = (isLoading: boolean) => (this.isLoading = isLoading)

  setHasSelectedDestination = (hasSelectedDestination: boolean) =>
    (this.hasSelectedDestination = hasSelectedDestination)

  open(containerNumber: string, orderId: number | null) {
    this.containerNumber = containerNumber
    this.orderId = orderId
    this.isOpen = true
  }

  close() {
    this.containerNumber = undefined
    this.orderId = null
    this.isOpen = false
  }
}
