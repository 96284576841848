import { extractSubFormData, FieldStore, ISubFormStore, validateSubForm } from '@tom-ui/utils'
import { computed, makeObservable } from 'mobx'
import { OogFormData } from './oog-helper'

export class OogSubFormStore implements ISubFormStore<OogFormData> {
  isOog: FieldStore<boolean>
  overLengthRear: FieldStore<number | undefined>
  overLengthFront: FieldStore<number | undefined>
  overWidthLeft: FieldStore<number | undefined>
  overWidthRight: FieldStore<number | undefined>
  overHeight: FieldStore<number | undefined>

  constructor() {
    makeObservable(this, {
      isOogSelected: computed,
    })

    this.isOog = new FieldStore<boolean>(false)
    this.overLengthRear = new FieldStore<number | undefined>(undefined)
    this.overLengthFront = new FieldStore<number | undefined>(undefined)
    this.overWidthLeft = new FieldStore<number | undefined>(undefined)
    this.overWidthRight = new FieldStore<number | undefined>(undefined)
    this.overHeight = new FieldStore<number | undefined>(undefined)
  }

  setValues(data?: OogFormData) {
    this.isOog.setValue(data?.isOog ?? false)
    this.overLengthRear.setValue(data?.overLengthRear ?? undefined)
    this.overLengthFront.setValue(data?.overLengthFront ?? undefined)
    this.overWidthLeft.setValue(data?.overWidthLeft ?? undefined)
    this.overWidthRight.setValue(data?.overWidthRight ?? undefined)
    this.overHeight.setValue(data?.overHeight ?? undefined)
  }

  validate = () => {
    return validateSubForm(this)
  }

  get isOogSelected() {
    return this.isOog.value
  }

  get data() {
    if (!this.isOogSelected) {
      return {
        isOog: false,
      }
    }

    return extractSubFormData(this)
  }
}
