import { Button, Stack } from '@mui/material'
import { CarrierType, CarrierVisitDirection } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { IContainerDamage } from '@planning/components'
import { RailcarOrderAssignmentOrderAutoComplete } from '@planning/components/autocompletes/container-autocomplete/UnassignedOrderAutoComplete'
import {
  createOogFormDataFromOrderResponse,
  OogFormData,
} from '@planning/components/organisms/OogSubForm/oog-helper'
import { NavigationStackBackButton } from '@planning/pages/TallymanV2/Components/HeaderComponents/NavigationStackBackButton'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { useTranslate } from '@tolgee/react'
import { ContainerMobile, Header, StepperInfo, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  IInspectContainerFormData,
  InspectContainerForm,
} from '../InspectContainer/InspectContainerForm'

interface IProps {
  orders: IOrderItem[]
  submitButtonText: string
  direction: CarrierVisitDirection
  onSubmit: (data: IInspectContainerFormData) => Promise<void> | void
  renderDeleteContainerDialog?: () => JSX.Element
}

interface ContainerOrder {
  id: number
  referenceNumber: string
  direction: CarrierVisitDirection
  carrierType: CarrierType
  containerId: number | null
  containerNumber: string
  containerIsoCode: string
  containerTare: number | null
  containerMaxGross: number | null
  isEmpty: boolean
  imoClasses: string[]
  damages: string[]
  seals: string[]
  hasDamage?: boolean
  damagesReported?: IContainerDamage[]
  oog?: OogFormData
}

export const CreateRailcarContainer: FC<IProps> = observer(
  ({ orders, submitButtonText, direction, onSubmit, renderDeleteContainerDialog }) => {
    const { t } = useTranslate()
    const { appViewStore } = usePlanningStore()
    const theme = useMinimalsTheme()

    const [selectedOrderItem, setSelectedOrderItem] = useState<IOrderItem | undefined>()

    const form = useForm({
      defaultValues: {
        id: -1,
        referenceNumber: '',
        direction: direction ?? CarrierVisitDirection.Inbound,
        carrierType: CarrierType.Train,
        containerId: null,
        containerNumber: '',
        containerIsoCode: '',
        containerTare: null,
        containerMaxGross: null,
        imoClasses: [],
        damages: [],
        seals: [],
        hasDamage: false,
        damagesReported: [],
        isEmpty: false,
      } as ContainerOrder,
    })

    const onFormSubmit = async (data: any) => {
      try {
        if (!data.containerNumber || data.containerNumber === '') {
          appViewStore.setShowAlert(
            'error',
            t('pleaseSelectAContainer', 'Please select a container'),
          )
          return
        }
        onSubmit(data)
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }

    const setFormValues = (orderItem: IOrderItem) => {
      setSelectedOrderItem(orderItem)
      const order = orderItem.data

      form.setValue('id', order.id ?? -1)
      form.setValue('referenceNumber', order.referenceNumber ?? '')
      form.setValue('imoClasses', order.imoClasses ?? [])
      // form.setValue('damages', orderItem.damages ?? '') // TODO: damages
      form.setValue('seals', order.seals ?? [])
      form.setValue('isEmpty', order.isEmpty ?? [])

      const container = orderItem.container?.data
      form.setValue('containerIsoCode', container?.isoCode ?? '')
      form.setValue('containerMaxGross', container?.maxGross ?? null)
      form.setValue('containerTare', container?.tare ?? null)
      form.setValue('containerId', container?.id ?? null)
      form.setValue('containerNumber', container?.number ?? '')
      form.setValue('oog', createOogFormDataFromOrderResponse(orderItem.data))
    }

    const getStepText = () => {
      return form.watch('hasDamage') ? t('1of2', '1 of 2') : undefined
    }

    const getButtonText = () => {
      return form.watch('hasDamage') ? t('next', 'Next') : submitButtonText
    }

    return (
      <>
        <Header
          leftRenderOption={<NavigationStackBackButton />}
          title={t('newContainer', 'New container')}
          rightRenderOption={
            <Stack flexDirection='row' gap={theme.customSpacing.xs}>
              <Button
                data-cy='submit-inspect-container'
                variant='contained'
                onClick={form.handleSubmit(onFormSubmit)}
              >
                {getButtonText()}
              </Button>
            </Stack>
          }
        />

        <StepperInfo title={t('inspectContainer', 'Inspect Container')} steps={getStepText()} />

        <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
          <FormProvider {...form}>
            <InspectContainerForm
              containerItem={selectedOrderItem?.container}
              containerAutocomplete={
                <RailcarOrderAssignmentOrderAutoComplete
                  orders={orders}
                  onChange={async (_, value) => {
                    if (value && typeof value != 'string' && value.id) {
                      setFormValues(value)
                    }
                  }}
                />
              }
            />
          </FormProvider>
        </ContainerMobile>

        {renderDeleteContainerDialog?.()}
      </>
    )
  },
)
