import { createSvgIcon } from '@mui/material'

export const ArrowUpRightIcon = createSvgIcon(
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='12' width='12' height='12' rx='6' transform='rotate(90 12 0)' fill='#E9FCD4' />
    <path
      d='M3.17157 3.1717L3.17157 4.16872L7.12076 4.17226L2.81802 8.475L3.52513 9.18211L7.82787 4.87936L7.82434 8.82856L8.82843 8.82856L8.82843 3.1717L3.17157 3.1717Z'
      fill='currentColor'
    />
  </svg>,
  'ArrowUpRightIcon',
)
